<template>
    <div>
        <errorMessage v-if="error" :message="errorMessage" @close="handleClose()"></errorMessage>
        <askStateModel v-if="askState"  @setState="setState"></askStateModel>
        <calendar :baseUrl="baseUrl" @SelectedTime="SelectedTime" :visit_type="visit_type" v-if="active_el == 1" @next="moveNext" />
       
    </div>
    </template>
    
    <script>
    import calendar from './CalDateSelect.vue';
    import askStateModel from './askStateModel.vue';
    import errorMessage from './errorMessage.vue';
    export default {
        props: ['baseUrl'],
        components: {
            calendar,
            errorMessage,
            askStateModel
        },
        data() {
            return {
                askState: false,
                active_el: 0,
                selectedTime: '',
                selectedDate: '',
                people: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: ''
                },
                visit_type: "initial",
                imageaccess: "",
                loading: false,
                pid: 0,
                show: 1,
                userTimeZone: '',
                error: false,
                errorMessage: "Time is no longer available. Please select a new time.",
            }
        },
        mounted(){
            this.checkState();
        },
        methods: {
            setState(statesel){
                console.log(statesel)
                localStorage.setItem("userState", JSON.stringify(statesel))
                let vm = this;
                setTimeout(function () {
                    vm.active_el=1;
                    vm.askState=false;
                }, 1000);
                
            },
            checkState(){
                if(this.$route.query.state){
                    
                    localStorage.setItem("userState", JSON.stringify(this.$route.query.state))
                    this.active_el=1;
                }else{
                    this.askState=true;
                    this.loading=false;
                }
            },
            AppointMentConfirmed(stattime, endtime) {
                this.appstart = stattime;
                this.appointend = endtime;
                this.active_el++;
            },
            SelectedTime(stime, sdate) {
                this.selectedTime = stime;
                this.selectedDate = sdate;
                if (this.health_id || this.paitient_id) {
                    this.scheduleAppointment();
                } else {
                    this.active_el++;
                }
    
            },
            ScheduleApp(data) {
                this.people = data;
                this.scheduleAppointment();
            },
            handleClose() {
                this.active_el = 1;
                this.error = false;
            },
            scheduleAppointment() {
                this.loading = true;
                let data = {
                    "health_id": this.health_id,
                    "paitient_id": this.paitient_id,
                    "visit_type": this.$route.query.visit_type,
                    "slot": JSON.parse(localStorage.getItem("app_slot")),
                    "state": JSON.parse(localStorage.getItem("userState")),
                    ...this.people
                };
                window.axios.post(`${this.baseUrl}/scheduling/schedule`, data).then((response) => {
                    if (response.data.status == 'success') {
                        this.$router.push({
                            name: 'ThanksAppointment',
                        });
                    } else {
                        this.active_el = 0;
                        this.loading = false;
                        this.error = true;
                        this.errorMessage = response.data.message;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            moveNext() {
                this.active_el++;
            },
    
        },
        created() {
            
            if (this.$route.query.health_id && this.$route.query.id) {
                this.health_id = this.$route.query.health_id;
                this.paitient_id = this.$route.query.id;
                this.visit_type = this.$route.query.app_type;
                localStorage.setItem("userState", JSON.stringify(this.$route.query.state))
            } else {
                this.$router.push({
                    name: 'QualifyingQuestion',
                });
            }
        }
    }
    </script>
    