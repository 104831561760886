<template>
    <section class="orig-backup">
    <div class="div-block-1391">
      <div class="w-layout-blockcontainer w-container">
        <div class="logo-nav-div">
          <a href="#" class="w-inline-block"><img src="@/assets/images/yara-placeholder.svg" loading="lazy" alt="" class="image-285"></a>
        </div>
        <div class="report-body-main-div">
          <div class="top-section-div">
            <div class="div-block-1395">
              <div class="text-block-462"><strong>Congrats {{ people.first_name }}!</strong> You meet the initial pre-qualifications.</div>
            </div>
          </div>
          <div class="report-summary-div">
            <div class="div-block-1534">
              <div class="div-block-1537">
                <div class="text-block-575">Weight Loss projection for:<br/> <strong>{{people.first_name}} {{ people.last_name }}</strong> </div>
              </div>
              <div class="div-block-1535">
                <div class="div-block-1536">
                  <div class="text-block-573">Your Current Weight </div>
                  <div class="text-block-574">{{people.weight}} <span class="text-span-107">lbs</span></div>
                </div>
                <div class="div-block-1536">
                  <div class="text-block-573">Your Target Weight is</div>
                  <div class="text-block-574">{{people.desired_weight}} <span class="text-span-107">lbs</span></div>
                </div>
              </div>
            </div>
            
            <div class="div-block-1527">
              <div class="div-block-1528">
                <div class="div-block-1529">
                  <div class="div-block-1530"></div>
                  <div class="div-block-1531">
                    <div class="text-block-571">% Loss</div>
                  </div>
                  <div class="div-block-1532">
                    <div class="text-block-571">Weight Loss</div>
                  </div>
                  <div class="div-block-1533">
                    <div class="text-block-571">New Weight</div>
                  </div>
                </div>
                <div class="div-block-1529">
                  <div class="div-block-1530">
                    <div class="text-block-571">Avg. Weight Loss on Semaglutide</div>
                  </div>
                  <div class="div-block-1531">
                    <div class="text-block-572">16%</div>
                  </div>
                  <div class="div-block-1532">
                    <div class="text-block-572">{{ (parseInt(people.weight) * 0.16).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                  <div class="div-block-1533">
                    <div class="text-block-572">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.16)).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                </div>
                <div class="div-block-1529">
                  <div class="div-block-1530">
                    <div class="text-block-571">Avg. Weight Loss on Tirzepatide</div>
                  </div>
                  <div class="div-block-1531">
                    <div class="text-block-572">22%</div>
                  </div>
                  <div class="div-block-1532">
                    <div class="text-block-572">{{ (parseInt(people.weight) * 0.22).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                  <div class="div-block-1533">
                    <div class="text-block-572">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.22)).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                </div>
                <div class="div-block-1529">
                  <div class="div-block-1530">
                    <div class="text-block-571">1 in 3 lose more on Semaglutide<br></div>
                  </div>
                  <div class="div-block-1531">
                    <div class="text-block-572">23%</div>
                  </div>
                  <div class="div-block-1532">
                    <div class="text-block-572">{{ (parseInt(people.weight) * 0.23).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                  <div class="div-block-1533">
                    <div class="text-block-572">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.23)).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                </div>
                <div class="div-block-1529">
                  <div class="div-block-1530">
                    <div class="text-block-571">1 in 5 on Yara’s Program Lose</div>
                  </div>
                  <div class="div-block-1531">
                    <div class="text-block-572">31%</div>
                  </div>
                  <div class="div-block-1532">
                    <div class="text-block-572">{{ (parseInt(people.weight) * 0.31).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                  <div class="div-block-1533">
                    <div class="text-block-572">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.31)).toFixed(0) }} <span class="text-span-108">lbs</span></div>
                  </div>
                </div>
              </div>
            </div>
            <p class="paragraph-111">These are for informational purposes only, based upon surveys done internally and studies conducted  by the New England Journal of Medicine. Individual results may vary.  We do provide a 90 day guarantee you lose 10% of body weight if you follow our system.</p>
          </div>
          <div class="next-steps-div">
            <div class="next-steps-box">
              <div class="next-step-header-div">
                <div class="text-block-715">What&#x27;s Next?</div>
                <div class="text-block-716">You are about to join thousands losing weight with Semaglutide &amp; Tirzepatide.</div>
              </div>
              <div class="step-entry-div">
                <div class="div-block-1662"><img src="@/assets/images/Vial_Yara.png" loading="lazy" alt="" class="image-376"></div>
                <div class="div-block-1663">
                  <div class="text-block-713">Select Your Prescription</div>
                  <div class="text-block-714">You&#x27;ll choose between Semaglutide &amp; Tirzepatide on the next page.</div>
                </div>
              </div>
              <div class="step-entry-div">
                <div class="div-block-1662"><img src="@/assets/images/icon_card.png" loading="lazy" alt="" class="image-379"></div>
                <div class="div-block-1663">
                  <div class="text-block-713">Pre-Authorize Your Card</div>
                  <div class="text-block-714">These medications are in high demand. We will need to pre-authorize your card today to secure your medication and telemed appointment. (Funds are held until shipment. If denied, funds will be released back to you.)</div>
                </div>
              </div>
              <div class="step-entry-div">
                <div class="div-block-1662"><img src="@/assets/images/icon_clipboard.png" loading="lazy" alt="" class="image-377"></div>
                <div class="div-block-1663">
                  <div class="text-block-713">Complete The Medical Intake</div>
                  <div class="text-block-714">Once your payment is made, we will send you an email with instructions on how to access your Direct Meds patient portal and complete a few medical questions for the doctor.</div>
                </div>
              </div>
              <div class="step-entry-div">
                <div class="div-block-1662"><img src="@/assets/images/stepsimage_teledoc.png" loading="lazy" sizes="(max-width: 479px) 9vw, 3vw" srcset="@/assets/images/stepsimage_teledoc-p-500.png 500w, @/assets/images/stepsimage_teledoc.png 641w" alt="" class="image-378"></div>
                <div class="div-block-1663">
                  <div class="text-block-713">Doctor&#x27;s Review</div>
                  <div class="text-block-714">The doctor will review your chart. (Usually within 5 hours including weekends.)</div>
                </div>
              </div>
              <div class="step-entry-div last">
                <div class="div-block-1662"><img src="@/assets/images/icon_van.png" loading="lazy" alt=""></div>
                <div class="div-block-1663">
                  <div class="text-block-713">Approval and Shipping</div>
                  <div class="text-block-714">Once approved, our FDA-registered pharmacy will ship your ice-packed medication with UPS next-day rush delivery. Orders ship same day, except Fridays. A tracking number will be provided to your email address when your medication ships.</div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="slider-table-div">
            <div class="div-block-1540">
              <div class="text-block-475 no-highlight"><strong>It is 2024</strong>...you do not have an excuse anymore to reach your weight goals.</div>
            </div>
            <div class="div-block-1538">
              <div data-delay="4000" data-animation="slide" class="slider w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
                <div class="w-slider-mask">
                  <div class="slide-4 w-slide">
                    <div class="div_vidcontainer">
                      <div class="div_bgvid">
                        <div data-poster-url="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c962bcd847368db5528_GOOD%20Weight-Loss274-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c962bcd847368db5528_GOOD%20Weight-Loss274-transcode.mp4,https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c962bcd847368db5528_GOOD%20Weight-Loss274-transcode.webm" data-autoplay="false" data-loop="true" data-wf-ignore="true" class="background-video w-background-video w-background-video-atom"><video id="526f5e26-28c6-632d-3e4c-b9181bb4d23b-video" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c962bcd847368db5528_GOOD%20Weight-Loss274-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c962bcd847368db5528_GOOD%20Weight-Loss274-transcode.mp4" data-wf-ignore="true">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c962bcd847368db5528_GOOD%20Weight-Loss274-transcode.webm" data-wf-ignore="true">
                          </video><noscript><img data-wf-bgvideo-fallback-img="true" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c962bcd847368db5528_GOOD%20Weight-Loss274-poster-00001.jpg" alt="">
                          </noscript>
                          <div aria-live="polite"><button type="button" data-w-bg-video-control="true" aria-controls="526f5e26-28c6-632d-3e4c-b9181bb4d23b-video" class="w-backgroundvideo-backgroundvideoplaypausebutton w-background-video--control"><span><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628299f8aa233b83918e24fd_Pause.svg" alt="Pause video"></span><span hidden=""><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628298b20ae0236682d4b87f_Play-24.svg" alt="Play video"></span></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide-4 w-slide">
                    <div class="div_vidcontainer">
                      <div class="div_bgvid">
                        <div data-poster-url="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d9e84888252c0dc1838_GOOD%20Weight-Loss289-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d9e84888252c0dc1838_GOOD%20Weight-Loss289-transcode.mp4,https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d9e84888252c0dc1838_GOOD%20Weight-Loss289-transcode.webm" data-autoplay="false" data-loop="true" data-wf-ignore="true" class="background-video w-background-video w-background-video-atom"><video id="526f5e26-28c6-632d-3e4c-b9181bb4d244-video" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d9e84888252c0dc1838_GOOD%20Weight-Loss289-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d9e84888252c0dc1838_GOOD%20Weight-Loss289-transcode.mp4" data-wf-ignore="true">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d9e84888252c0dc1838_GOOD%20Weight-Loss289-transcode.webm" data-wf-ignore="true">
                          </video><noscript><img data-wf-bgvideo-fallback-img="true" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d9e84888252c0dc1838_GOOD%20Weight-Loss289-poster-00001.jpg" alt="">
                          </noscript>
                          <div aria-live="polite"><button type="button" data-w-bg-video-control="true" aria-controls="526f5e26-28c6-632d-3e4c-b9181bb4d244-video" class="w-backgroundvideo-backgroundvideoplaypausebutton w-background-video--control"><span><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628299f8aa233b83918e24fd_Pause.svg" alt="Pause video"></span><span hidden=""><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628298b20ae0236682d4b87f_Play-24.svg" alt="Play video"></span></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide-4 w-slide">
                    <div class="div_vidcontainer">
                      <div class="div_bgvid">
                        <div data-poster-url="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37cdafac423ae4e69f502_GOOD%20Weight-Loss278-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37cdafac423ae4e69f502_GOOD%20Weight-Loss278-transcode.mp4,https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37cdafac423ae4e69f502_GOOD%20Weight-Loss278-transcode.webm" data-autoplay="false" data-loop="true" data-wf-ignore="true" class="background-video w-background-video w-background-video-atom"><video id="526f5e26-28c6-632d-3e4c-b9181bb4d24d-video" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37cdafac423ae4e69f502_GOOD%20Weight-Loss278-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37cdafac423ae4e69f502_GOOD%20Weight-Loss278-transcode.mp4" data-wf-ignore="true">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37cdafac423ae4e69f502_GOOD%20Weight-Loss278-transcode.webm" data-wf-ignore="true">
                          </video><noscript><img data-wf-bgvideo-fallback-img="true" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37cdafac423ae4e69f502_GOOD%20Weight-Loss278-poster-00001.jpg" alt="">
                          </noscript>
                          <div aria-live="polite"><button type="button" data-w-bg-video-control="true" aria-controls="526f5e26-28c6-632d-3e4c-b9181bb4d24d-video" class="w-backgroundvideo-backgroundvideoplaypausebutton w-background-video--control"><span><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628299f8aa233b83918e24fd_Pause.svg" alt="Pause video"></span><span hidden=""><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628298b20ae0236682d4b87f_Play-24.svg" alt="Play video"></span></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide-4 w-slide">
                    <div class="div_vidcontainer">
                      <div class="div_bgvid">
                        <div data-poster-url="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d1ccfa9a8ade07d3755_GOOD%20Weight-Loss279-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d1ccfa9a8ade07d3755_GOOD%20Weight-Loss279-transcode.mp4,https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d1ccfa9a8ade07d3755_GOOD%20Weight-Loss279-transcode.webm" data-autoplay="false" data-loop="true" data-wf-ignore="true" class="background-video w-background-video w-background-video-atom"><video id="526f5e26-28c6-632d-3e4c-b9181bb4d256-video" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d1ccfa9a8ade07d3755_GOOD%20Weight-Loss279-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d1ccfa9a8ade07d3755_GOOD%20Weight-Loss279-transcode.mp4" data-wf-ignore="true">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d1ccfa9a8ade07d3755_GOOD%20Weight-Loss279-transcode.webm" data-wf-ignore="true">
                          </video><noscript><img data-wf-bgvideo-fallback-img="true" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d1ccfa9a8ade07d3755_GOOD%20Weight-Loss279-poster-00001.jpg" alt="">
                          </noscript>
                          <div aria-live="polite"><button type="button" data-w-bg-video-control="true" aria-controls="526f5e26-28c6-632d-3e4c-b9181bb4d256-video" class="w-backgroundvideo-backgroundvideoplaypausebutton w-background-video--control"><span><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628299f8aa233b83918e24fd_Pause.svg" alt="Pause video"></span><span hidden=""><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628298b20ae0236682d4b87f_Play-24.svg" alt="Play video"></span></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide-4 w-slide">
                    <div class="div_vidcontainer">
                      <div class="div_bgvid">
                        <div data-poster-url="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d50b541310f0904a443_GOOD%20Weight-Loss288-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d50b541310f0904a443_GOOD%20Weight-Loss288-transcode.mp4,https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d50b541310f0904a443_GOOD%20Weight-Loss288-transcode.webm" data-autoplay="false" data-loop="true" data-wf-ignore="true" class="background-video w-background-video w-background-video-atom"><video id="526f5e26-28c6-632d-3e4c-b9181bb4d25f-video" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d50b541310f0904a443_GOOD%20Weight-Loss288-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d50b541310f0904a443_GOOD%20Weight-Loss288-transcode.mp4" data-wf-ignore="true">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d50b541310f0904a443_GOOD%20Weight-Loss288-transcode.webm" data-wf-ignore="true">
                          </video><noscript><img data-wf-bgvideo-fallback-img="true" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37d50b541310f0904a443_GOOD%20Weight-Loss288-poster-00001.jpg" alt="">
                          </noscript>
                          <div aria-live="polite"><button type="button" data-w-bg-video-control="true" aria-controls="526f5e26-28c6-632d-3e4c-b9181bb4d25f-video" class="w-backgroundvideo-backgroundvideoplaypausebutton w-background-video--control"><span><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628299f8aa233b83918e24fd_Pause.svg" alt="Pause video"></span><span hidden=""><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628298b20ae0236682d4b87f_Play-24.svg" alt="Play video"></span></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide-4 w-slide">
                    <div class="div_vidcontainer">
                      <div class="div_bgvid">
                        <div data-poster-url="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c4feb44a6fc2db14386_GOOD%20Weight-Loss271-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c4feb44a6fc2db14386_GOOD%20Weight-Loss271-transcode.mp4,https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c4feb44a6fc2db14386_GOOD%20Weight-Loss271-transcode.webm" data-autoplay="false" data-loop="true" data-wf-ignore="true" class="background-video w-background-video w-background-video-atom"><video id="526f5e26-28c6-632d-3e4c-b9181bb4d268-video" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c4feb44a6fc2db14386_GOOD%20Weight-Loss271-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c4feb44a6fc2db14386_GOOD%20Weight-Loss271-transcode.mp4" data-wf-ignore="true">
                            <source src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c4feb44a6fc2db14386_GOOD%20Weight-Loss271-transcode.webm" data-wf-ignore="true">
                          </video><noscript><img data-wf-bgvideo-fallback-img="true" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8%2F66b37c4feb44a6fc2db14386_GOOD%20Weight-Loss271-poster-00001.jpg" alt="">
                          </noscript>
                          <div aria-live="polite"><button type="button" data-w-bg-video-control="true" aria-controls="526f5e26-28c6-632d-3e4c-b9181bb4d268-video" class="w-backgroundvideo-backgroundvideoplaypausebutton w-background-video--control"><span><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628299f8aa233b83918e24fd_Pause.svg" alt="Pause video"></span><span hidden=""><img loading="lazy" src="https://uploads-ssl.webflow.com/6022af993a6b2191db3ed10c/628298b20ae0236682d4b87f_Play-24.svg" alt="Play video"></span></button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="left-arrow w-slider-arrow-left">
                  <div class="icon-23 w-icon-slider-left"></div>
                </div>
                <div class="right-arrow w-slider-arrow-right">
                  <div class="icon-22 w-icon-slider-right"></div>
                </div>
                <div class="w-slider-nav w-slider-nav-invert w-round"></div>
              </div>
              <p class="paragraph-111">The individuals in this testimonial are GLP1 Weight Loss users and were compensated for their video testimonial by Yara. Individual results may vary. A provider may choose to prescribe compounded GLP-1 medications, which are prepared by a state-licensed sterile compounding pharmacy but are not FDA-approved and do not undergo safety, effectiveness, or manufacturing review.</p>
            </div>
            <div class="div-block-1402">
              <div class="div-block-1404"></div>
            </div>
          </div>
          <div class="report-testimonial-div">
            <div class="div-block-1407"><img src="@/assets/images/merris-_-before--after.jpg" loading="lazy" sizes="(max-width: 479px) 75vw, (max-width: 767px) 24vw, (max-width: 991px) 26vw, 30vw" srcset="@/assets/images/merris-_-before--after-p-500.jpg 500w, @/assets/images/merris-_-before--after-p-800.jpg 800w, @/assets/images/merris-_-before--after-p-1080.jpg 1080w, @/assets/images/merris-_-before--after-p-1600.jpg 1600w, @/assets/images/merris-_-before--after-p-2000.jpg 2000w, @/assets/images/merris-_-before--after.jpg 2149w" alt="" class="image-286"></div>
            <div class="div-block-1408"><img src="@/assets/images/icon_stars.svg" loading="lazy" alt="" class="image-287">
              <div class="text-block-471">Yara helped me achieve weight goals I never thought possible.</div>
              <p class="paragraph-98">Even as a nutritionist I still struggled to lose weight with diet alone. With GLP1s it was a matter of weeks before the weight started dropping off.</p>
              <div class="text-block-472">Merris, lost 55 lbs.</div>
            </div>
          </div>
          <div class="expectations-div">
            <div class="text-block-475">What You can Expect</div>
            <p class="paragraph-99">Join the millions of Americans losing weight with Semaglutide and <strong>start seeing results in as little as 4 weeks!</strong></p>
            <div class="div-block-1409">
              <div class="div-block-1410">
                <div class="div-block-1412">
                  <div class="text-block-473">Lose weight</div>
                  <div class="text-block-474">On average, Semaglutide users <span class="text-span-97">lose around 16%</span> of their body weight</div>
                </div>
                <div class="div-block-1412">
                  <div class="text-block-473">Feel fuller, longer</div>
                  <div class="text-block-474">Semaglutide <span class="text-span-97">suppresses appetite</span> and curbs food cravings**</div>
                </div>
              </div>
              <div class="div-block-1410">
                <div class="div-block-1412">
                  <div class="text-block-473">Reduce cholesterol</div>
                  <div class="text-block-474">Semaglutide has been shown to <span class="text-span-97">reduce total cholesterol, </span> LDL cholesterol &amp; triglycerides**</div>
                </div>
                <div class="div-block-1412">
                  <div class="text-block-473">Improve heart health</div>
                  <div class="text-block-474"><span class="text-span-97">Prevent heart attacks</span> and reduce the risk of major cardiac events***</div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="report-testimonial-div">
            <div class="div-block-1407"><img src="@/assets/images/johnna_ba.png" loading="lazy" sizes="(max-width: 479px) 75vw, (max-width: 767px) 24vw, (max-width: 991px) 26vw, 30vw" srcset="@/assets/images/johnna_ba-p-500.png 500w, @/assets/images/johnna_ba-p-800.png 800w, @/assets/images/johnna_ba-p-1080.png 1080w, @/assets/images/johnna_ba.png 1182w" alt="" class="image-286"></div>
            <div class="div-block-1408"><img src="@/assets/images/icon_stars.svg" loading="lazy" alt="" class="image-287">
              <div class="text-block-471">I couldn&#x27;t imagine life without my GLP weekly routine!</div>
              <p class="paragraph-98">&quot;I went from 357 pounds all the way to to 145 pounds...all thanks to a healthier diet, exercise and most import my weekly injection!&quot;</p>
              <div class="text-block-472">Johnna, lost 212 lbs.</div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    <div class="w-layout-blockcontainer container_profile-button w-container" @click="choosePlanPage($event)">
        <a href="#" @click="choosePlanPage($event)" class="link-block-37 w-inline-block">
            <div class="text-block-665">Start Free Medical Evaluation
            <span class="text-span-141">-&gt;</span></div>
            <div class="text-block-646">Click to change your life.</div>
        </a>
    </div>
  </section>
  
</template>
<script>
export default {
  props: ['baseUrl'],
  data() {
    return {
      externalScript:null,
      people: {}
    }
  },
  mounted() {
    this.getParameter();
    this.externalScript = document.createElement('script');
    this.externalScript.src = 'https://comjoinyara.s3.us-east-2.amazonaws.com/assets/js/webflow.js'; // External JS URL
    this.externalScript.async = true;
    document.head.appendChild(this.externalScript);
  },
  methods: {
    getParameter(){
        this.people ={
            ...this.$route.query
        }
    },
    choosePlanPage(event){
        event.preventDefault();
        this.$router.push({
            name: 'ChoosePlan',
            query: this.people
        });
    }
  }
}
</script>
<style scoped>
.link-block-37 {
  background-color: #000;
  border-radius: 11px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  text-decoration: none;
  display: flex;
  background-color: var(--bright-green);
}

.link-block-37:hover {
  background-color: var(--black);
  flex-flow: column;
  align-items: center;
  display: flex;
}
</style>