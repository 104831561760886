<template>
<div>
    <div>
        <div class="form-3-header-div">
            <div class="div-block-966 presell">
                <a href="#" class="w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg" alt="" class="image-30"></a>
            </div>
        </div>
    </div>
    <section>
        <div class="div-block-1668">
            <div class="w-layout-blockcontainer container-23 w-container">
                <div class="div-block-1671">
                    <div class="text-block-736">Skip the Virtual Visit!</div>
                    <p class="paragraph-127">Based on your responses, the initial <strong>virtual doctor visit is not
                            required.</strong><br></p>
                </div>
                <div class="form-block-6 w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-9" data-wf-page-id="66e2d63ba58948490f7db1a8" data-wf-element-id="6cb9b46d-c1aa-68ad-5aae-6d38c5369ee5">
                        <div class="bullet-points">
                            <div class="div-block-1674"><img src="@/assets/images/stepsimage_teledoc.png" loading="lazy" sizes="(max-width: 991px) 40px, 100vw" srcset="@/assets/images/stepsimage_teledoc-p-500.png 500w, @/assets/images/stepsimage_teledoc.png 641w" alt="" class="image-384">
                                <div>
                                    <div class="text-block-735">Doctor will review &amp; prescribe.</div>
                                    <div class="text-block-737">Our clinicians will review your info &amp; send script to partner
                                        pharmacy.</div>
                                </div>
                            </div>
                            <div class="div-block-1674"><img src="@/assets/images/icon_van.png" loading="lazy" alt="" class="image-384">
                                <div>
                                    <div class="text-block-735">Express Shipping</div>
                                    <div class="text-block-737">We will ship your medication express so you can start ASAP!</div>
                                </div>
                            </div>
                            <div class="div-block-1674"><img src="@/assets/images/icon_doc.png" loading="lazy" alt="" class="image-384">
                                <div>
                                    <div class="text-block-735">Unlimited Clinical Support</div>
                                    <div class="text-block-737">Once the medicine arrives you will gain unlimited access to doctors by
                                        phone &amp; email to answer any questions.</div>
                                </div>
                            </div>
                            <div class="div-block-1674"><img src="@/assets/images/icon_guarantee.png" loading="lazy" alt="" class="image-384">
                                <div>
                                    <div class="text-block-735">3 Month Guarantee</div>
                                    <div class="text-block-737">Follow our program. Lose 10% of body weight in 90 days or your money
                                        back.</div>
                                </div>
                            </div>
                        </div>
                        <div class="div-block-1676">
                            
                            <button class="button-79 w-button" @click="chargeUser($event)" style="height: 75px;">
                                <span v-if="submitted" style="display:flex; align-items: center; justify-content: center;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="35" height="35" style="shape-rendering: auto; display: block; background: transparent;" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g>
                                            <circle stroke-dasharray="155.50883635269477 53.83627878423159" r="33" stroke-width="11" stroke="#faf8f8" fill="none" cy="50" cx="50">
                                                <animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform>
                                            </circle>
                                            <g></g>
                                        </g>
                                    </svg>
                                </span>
                                <span v-else>Yes. I&#x27;m ready to go!<br><span class="text-span-154">You will be charged for the
                                        first month.</span></span>
                            </button>
                        </div>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail" style="display: block;" v-if="error">
                        <div>{{errorMessage}}</div>
                    </div>
                </div>
                <div class="div-block-1667"></div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
export default {
    props: ['baseUrl'],

    data() {
        return {
            error: false,
            errorMessage: '',
            submitted: false,
        }
    },
    created() {

    },
    mounted() {
        this.scrollToTop();
        
    },
    methods: {
        getUtmParameters() {
            const urlParams = new URLSearchParams(window.location.search);
            const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'tid'];
            params.forEach(param => {
                this.people[param] = urlParams.get(param) || this.getCookie(param) || '';
            });
            console.log(this.people)
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            return parts.length === 2 ? parts.pop().split(';').shift() : null;
        },
        scrollToTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
        chargeUser(event) {
            event.preventDefault();
            this.error = false;
            this.submitted = true;
            window.axios.post(`${this.baseUrl}/charge-client/${this.$route.query.id}`, {
                charge_now: "ok"
            }).then((response) => {
                if (response.data.status == "success") {
                    this.sendToThankyouPage();
                    this.registerAffiliate();
                } else {
                    this.error = true;
                    this.submitted = false;
                    this.errorMessage = response.data.message;
                }
            }).catch((error) => {
                this.error = true;
                this.submitted = false;
                this.errorMessage = error.data.message;
            });
        },
        registerAffiliate(){
          try{
            let data = JSON.parse(localStorage.getItem("aff_tracking"))
            window.axios.post(`${this.baseUrl}/register-affiliate`, data);
          }catch(error){
            console.log(error.message)
          }
        },
        sendToThankyouPage() {
            window.axios.get(`${this.baseUrl}/save-create-client/${this.$route.query.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                if (response.data.status == "success") {
                    this.$router.push({
                        name: 'AsyncThanks',
                        query: {
                            "health_id": response.data.health_id,
                            "id": this.$route.query.id,
                            "state": this.state,
                            "visit_type": "initial_visit"
                        }
                    });
                }
            }).catch((error) => {
                alert(error.message)
            });
        }
    }
}
</script>

