<template>
    <div class="div-block-1654">
      <input
        v-if="!selectedAddress"
        ref="autocomplete"
        v-model="address"
        type="text"
        placeholder="Enter your address"
        :disabled="!isGoogleLoaded"
        class="text-field-7 new step-1 w-input"
        style="width:100%"
      />
      <div v-if="selectedAddress">
        <div class="div-block-1654">
          <input v-model="selectedAddress.street" class="text-field-7 new step-1 w-input" placeholder="Street" @input="onAddressChange" />
            <input class="text-field-7 new step-1 apt w-input" style="margin-left: 10px;" maxlength="256" v-model="address_line2" name="Apt-2" data-name="Apt 2" placeholder="Apt #" type="text" id="Apt-2" @input="onAddressChange">
        </div>
        <div class="div-block-1654">
          <input v-model="selectedAddress.city" class="text-field-7 new step-1 apt w-input" placeholder="City" @input="onAddressChange" style="width:33%"/>
          <input v-model="selectedAddress.state" placeholder="State" class="text-field-7 new apt step-1 w-input" @input="onAddressChange" style="margin-left:10px;width:33%"/>
            <input v-model="selectedAddress.zip" placeholder="ZIP Code" class="text-field-7 new step-1 apt w-input" @input="onAddressChange" style="margin-left:10px;width:33%"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GooglePlacesAutocomplete',
    
    data() {
      return {
        apiKey: process.env.VUE_APP_GOOGLE_KEY,
        address: '',
        address_line2: '',
        autocomplete: null,
        selectedAddress: null,
        isGoogleLoaded: false
      }
    },
    mounted() {
      this.loadGoogleMapsAPI()
    },
    methods: {
      loadGoogleMapsAPI() {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`
        script.async = true
        script.defer = true
        script.onload = () => {
          this.isGoogleLoaded = true
          this.initAutocomplete()
        }
        document.head.appendChild(script)
      },
      initAutocomplete() {
        if (!window.google) {
          console.error('Google Maps API not loaded')
          return
        }
        const options = {
          types: ['address'],
          componentRestrictions: { country: 'us' }
        }
        this.autocomplete = new window.google.maps.places.Autocomplete(
          this.$refs.autocomplete,
          options
        )
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect)
      },
      handlePlaceSelect() {
        const place = this.autocomplete.getPlace()
        if (!place.geometry) {
          console.log("No details available for input: '" + place.name + "'")
          return
        }
        
        this.selectedAddress = {
          street: '',
          city: '',
          state: '',
          zip: ''
        }
        
        for (const component of place.address_components) {
          const type = component.types[0]
          if (type === 'street_number') {
            this.selectedAddress.street = component.long_name + ' '
          }
          if (type === 'route') {
            this.selectedAddress.street += component.long_name
          }
          if (type === 'locality') {
            this.selectedAddress.city = component.long_name
          }
          if (type === 'administrative_area_level_1') {
            this.selectedAddress.state = component.short_name
          }
          if (type === 'postal_code') {
            this.selectedAddress.zip = component.long_name
          }
        }
        
        // Attempt to extract address line 2
        const formattedAddress = place.formatted_address
        const parts = formattedAddress.split(',')
        if (parts.length > 1) {
          const firstPart = parts[0].trim()
          if (firstPart.includes(' ')) {
            const potentialSubpremise = firstPart.split(' ').pop()
            if (potentialSubpremise.match(/^\d+[a-zA-Z]?$|^[a-zA-Z]$/)) {
              this.address_line2 = potentialSubpremise
              this.selectedAddress.street = this.selectedAddress.street.replace(potentialSubpremise, '').trim()
            }
          }
        }
  
        this.$emit('address-updated', { ...this.selectedAddress, address_line2: this.address_line2 })
      },
      onAddressChange() {
        this.$emit('address-updated', { ...this.selectedAddress, address_line2: this.address_line2 })
      },
      confirmAddress() {
        this.$emit('address-updated', { ...this.selectedAddress, address_line2: this.address_line2 })
      },
      resetAddress() {
        this.selectedAddress = null
        this.address = ''
        this.address_line2 = ''
        this.$nextTick(() => {
          this.$refs.autocomplete.focus()
        })
      }
    }
  }
  </script>
  
  <style>
  .address-split {
    justify-content: space-between;
    display: flex;
}
  /* Custom styles for Google Places Autocomplete */
  .pac-container {
    font-family: 'Readex Pro', sans-serif; /* Replace with your site's font */
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  
  .pac-item {
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .pac-item:hover {
    background-color: #f0f0f0;
  }
  
  .pac-icon {
    display: none; /* Hide the default icon if you prefer */
  }
  
  
  </style>