<template>
    <section class="orig-backup">
        <div class="div-block-1391">
            <div class="w-layout-blockcontainer w-container">
                <div class="div-block-1392"><br/><br/>
                    <a href="#" class="w-inline-block"><img src="@/assets/images/yara-placeholder.svg" loading="lazy"
                            alt="" class="image-285"></a>
                </div>
                <div class="div-block-1393">
                    <div class="div-block-1433">
                        <div class="div-block-1395">
                            <div class="text-block-462"><br/>🎉 <strong>Congrats {{ people.first_name }}! </strong>You are a
                                perfect
                                match for our Medical weight loss program!</div>
                            <p class="paragraph-97"><br/><br/><strong>You are only days away </strong>from starting the
                                <strong>last</strong> weight loss program you will ever need.<br><br>Below you will find
                                a <strong>preliminary report </strong>of the potential weight loss you could
                                achieve...however we have seen people CRUSH these numbers.
                            </p>
                            <a href="#" @click="pushToRoute($event)" class="link-block-34 w-inline-block">
                                <div class="text-block-576">Start Your Journey -&gt;</div>
                                <div class="text-block-577">Don&#x27;t wait another day to drop Lbs</div>
                            </a>
                        </div>
                    </div>
                    <div v-if="isDiscountOffer">
                        <div style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
                            <p class="paragraph-97" style="margin-bottom: 10px;font-size: 22px;"><br/><br/><strong>The Discount
                                    for ${{ discount }} expires in:</strong></p>
                        </div>
                        <div id="hereitwill"></div>
                        <div style="margin-bottom: 20px;"></div>
                    </div>
                    <div class="div-block-1539">
                        <div class="div-block-1534">
                            <div class="div-block-1537">
                                <div class="text-block-575">{{ people.first_name }}, This is the start of your weight
                                    loss
                                    journey</div>
                            </div>
                            <div class="div-block-1535">
                                <div class="div-block-1536">
                                    <div class="text-block-573">Your Current Weight is</div>
                                    <div class="text-block-574">{{ people.weight }} <span
                                            class="text-span-107">lbs</span>
                                    </div>
                                </div>
                                <div class="div-block-1536">
                                    <div class="text-block-573">Your Target Weight is</div>
                                    <div class="text-block-574">{{ people.desired_weight }} <span
                                            class="text-span-107">lbs</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="div-block-1397">
                            <div class="div-block-1434">
                                <div class="text-block-468">Average Weight Loss On Semaglutide</div>
                                <div class="div-block-1401"></div>
                                <div class="div-block-1435">
                                    <div class="div-block-1436">
                                        <div class="text-block-499">Fad Diet</div>
                                        <div class="text-block-500">-5<sup>%</sup></div>
                                    </div>
                                    <div class="div-block-1437">
                                        <div class="text-block-501">With Semaglutide</div>
                                        <div class="text-block-502">-16<sup>%</sup></div>
                                    </div>
                                    <div class="div-block-1438">
                                        <div class="text-block-503">Your Current Weight</div>
                                        <div class="text-block-504">400 lbs</div>
                                        <div class="div-block-1439 _1">
                                            <div class="text-block-503">Weight you Can Lose</div>
                                            <div class="text-block-504">64 lbs</div>
                                        </div>
                                        <div class="div-block-1439 _2">
                                            <div class="text-block-503">Your New Weight</div>
                                            <div class="text-block-504">336 lbs</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="div-block-1394">
                                <div class="div-block-1395" style="background-color: #fff;"><img
                                        src="@/assets/images/Yara-Table-2-01.png" loading="lazy"
                                        sizes="(max-width: 479px) 91vw, (max-width: 767px) 33vw, 34vw"
                                        srcset="@/assets/images/Yara-Table-2-01-p-500.png 500w, @/assets/images/Yara-Table-2-01.png 800w"
                                        alt=""></div>
                            </div>
                            <div class="div-block-1398 small">
                                <div class="text-block-468 small">1 in 3 Lose 23% of Body Weight</div>
                                <div class="div-block-1401"></div>
                                <div class="div-block-1399">
                                    <div class="div-block-1414">
                                        <div class="text-block-467 small">Weight you could Lose</div>
                                    </div>
                                    <div class="div-block-1400">
                                        <div class="text-block-463 small">46</div>
                                        <div class="text-block-464 small">lbs.</div>
                                    </div>
                                    <div>
                                        <div class="text-block-465 small">Your New Weight</div>
                                        <div class="text-block-466 small">154 lbs.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="div-block-1398 small">
                                <div class="text-block-468 small">People on Yara&#x27;s 30-day Challenge</div>
                                <div class="div-block-1401"></div>
                                <div class="div-block-1399">
                                    <div class="div-block-1414">
                                        <div class="text-block-467 small">Weight you could Lose</div>
                                    </div>
                                    <div class="div-block-1400">
                                        <div class="text-block-463 small">62</div>
                                        <div class="text-block-464 small">lbs.</div>
                                    </div>
                                    <div>
                                        <div class="text-block-465 small">Your New Weight</div>
                                        <div class="text-block-466 small">148 lbs.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="div-block-1527">
                            <div class="text-block-468">Weight Loss Projections<br>for
                                <strong>{{ people.first_name }}</strong><br>
                            </div>
                            <div class="div-block-1401"></div>
                            <div class="div-block-1528">
                                <div class="div-block-1529">
                                    <div class="div-block-1530"></div>
                                    <div class="div-block-1531">
                                        <div class="text-block-571">% Loss</div>
                                    </div>
                                    <div class="div-block-1532">
                                        <div class="text-block-571">Weight Loss</div>
                                    </div>
                                    <div class="div-block-1533">
                                        <div class="text-block-571">New Weight</div>
                                    </div>
                                </div>
                                <div class="div-block-1529">
                                    <div class="div-block-1530">
                                        <div class="text-block-571">Avg. Weight Loss on Semaglutide</div>
                                    </div>
                                    <div class="div-block-1531">
                                        <div class="text-block-572">16%</div>
                                    </div>
                                    <div class="div-block-1532">
                                        <div class="text-block-572">{{ (parseInt(people.weight) * 0.16).toFixed(0) }}
                                            <span class="text-span-108">lbs</span>
                                        </div>
                                    </div>
                                    <div class="div-block-1533">
                                        <div class="text-block-572">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.16)).toFixed(0) }} <span
                                                class="text-span-108">lbs</span></div>
                                    </div>
                                </div>
                                <div class="div-block-1529">
                                    <div class="div-block-1530">
                                        <div class="text-block-571">Avg. Weight Loss on Tirzepatide</div>
                                    </div>
                                    <div class="div-block-1531">
                                        <div class="text-block-572">22%</div>
                                    </div>
                                    <div class="div-block-1532">
                                        <div class="text-block-572">{{ (parseInt(people.weight) * 0.22).toFixed(0) }}
                                            <span class="text-span-108">lbs</span>
                                        </div>
                                    </div>
                                    <div class="div-block-1533">
                                        <div class="text-block-572">{{ (parseInt(people.weight) -
                                            (parseInt(people.weight) * 0.22)).toFixed(0) }} <span
                                                class="text-span-108">lbs</span></div>
                                    </div>
                                </div>
                                <div class="div-block-1529">
                                    <div class="div-block-1530">
                                        <div class="text-block-571">1 in 3 lose more on Semaglutide<br></div>
                                    </div>
                                    <div class="div-block-1531">
                                        <div class="text-block-572">23%</div>
                                    </div>
                                    <div class="div-block-1532">
                                        <div class="text-block-572">{{ (parseInt(people.weight) * 0.23).toFixed(0) }}
                                            <span class="text-span-108">lbs</span>
                                        </div>
                                    </div>
                                    <div class="div-block-1533">
                                        <div class="text-block-572">{{ (parseInt(people.weight) -
                                            (parseInt(people.weight) * 0.23)).toFixed(0) }} <span
                                                class="text-span-108">lbs</span></div>
                                    </div>
                                </div>
                                <div class="div-block-1529">
                                    <div class="div-block-1530">
                                        <div class="text-block-571">1 in 5 on Yara’s Program Lose</div>
                                    </div>
                                    <div class="div-block-1531">
                                        <div class="text-block-572">31%</div>
                                    </div>
                                    <div class="div-block-1532">
                                        <div class="text-block-572">{{ (parseInt(people.weight) * 0.31).toFixed(0) }}
                                            <span class="text-span-108">lbs</span>
                                        </div>
                                    </div>
                                    <div class="div-block-1533">
                                        <div class="text-block-572">{{ (parseInt(people.weight) -
                                            (parseInt(people.weight) * 0.31)).toFixed(0) }} <span
                                                class="text-span-108">lbs</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <p class="paragraph-111" style="margin-top:10px;"><sup>*</sup>These calculations are for informative
                        purposes only and do not constitute any guarantee. These calculations are derived from multiple
                        studies internally of Yara patients who we monitored throughout a 6 month period who followed
                        our entire nutritional program, excercise and GLP program. The average Semaglutide and
                        Tirzepatide weight lose estimates were derived from an extensive study by: <a
                            href="https://www.nejm.org/doi/full/10.1056/NEJMoa2206038" target="_blank">The New England
                            Journal of Medicine.</a></p>
                    <div class="div-block-1396">
                        <div class="div-block-1540">
                            <div class="text-block-475 no-highlight"><strong>It is 2024</strong>...you do not have an
                                excuse anymore to reach your weight goals.</div>
                        </div>
                        <div class="div-block-1538">
                            <div data-delay="4000" data-animation="slide" class="slider w-slider" data-autoplay="false"
                                data-easing="ease" data-hide-arrows="false" data-disable-swipe="false"
                                data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
                                <div class="w-slider-mask" ref="carouselContent">
                                    <div class="slide-4 w-slide" v-for="(video, index) in videos" :key="index">
                                        <div class="div_vidcontainer">
                                            <div class="div_bgvid">
                                                <div
                                                    class="background-video w-background-video w-background-video-atom">
                                                    <video :id="'video-' + index" loop
                                                        style="background-image:url(&quot;@/assets/videos/GOOD-Weight-Loss274-poster-00001.jpg&quot;)"
                                                        muted playsinline :src="video.src" :ref="`video-${index}`"
                                                        @play="updatePlayState(index, true)"
                                                        @pause="updatePlayState(index, false)"
                                                        :class="{ 'playing': video.isPlaying }" :poster="video.poster">
                                                        <source :src="video.srcMp4" type="video/mp4" />
                                                        <source :src="video.srcWebm" type="video/webm" />
                                                    </video>
                                                    <noscript>
                                                        <img :src="video.poster" alt="Video Poster" />
                                                    </noscript>
                                                    <div aria-live="polite">
                                                        <button type="button" @click="togglePlayPause(index)"
                                                            :aria-controls="'video-' + index"
                                                            class="w-backgroundvideo-backgroundvideoplaypausebutton w-background-video--control">
                                                            <span v-if="!video.isPlaying">
                                                                <img src="@/assets/images/play-24.svg" alt="Play video" />
                                                            </span>
                                                            <span v-else>
                                                                <img src="@/assets/images/pause-circle-svgrepo-com.svg" alt="Pause video" />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-arrow w-slider-arrow-left" @click="scrollLeft">
                                    <div class="icon-23 w-icon-slider-left"></div>
                                </div>
                                <div class="right-arrow w-slider-arrow-right" @click="scrollRight">
                                    <div class="icon-22 w-icon-slider-right"></div>
                                </div>

                            </div>
                            <p class="paragraph-111">The individuals in this testimonial are GLP1 Weight Loss users and
                                were compensated for their video testimonial by Yara. Individual results may vary. A
                                provider may choose to prescribe compounded GLP-1 medications, which are prepared by a
                                state-licensed sterile compounding pharmacy but are not FDA-approved and do not undergo
                                safety, effectiveness, or manufacturing review.</p>
                        </div>
                        <div class="div-block-1402">
                            <div class="div-block-1404">
                                <div class="div-block-1403">
                                    <div class="text-block-469">Big Savings <span class="text-span-100">PLUS</span>
                                        <sup>$</sup>{{ discount }} off!
                                    </div>
                                    <div class="text-block-470">
                                        <span class="text-span-95"><sup>$</sup>349</span>
                                        <span class="text-span-96"><sup>$</sup>{{ getPrice }}</span></div>
                                </div>
                                <div class="div-block-1405">
                                    <div class="summary-entry">
                                        <div class="div-block-1541"><img src="@/assets/images/icon_vial-check.svg"
                                                loading="lazy" alt="" class="image-278 report">
                                            <div class="item-detail _1">
                                                <div class="text-block-435 report">First Month Supply with
                                                    <sup>$</sup>{{ discount }} discount
                                                </div>
                                                <div class="text-block-437 report">Month 2 onwards $349</div>
                                            </div>
                                        </div>
                                        <div class="item-price">
                                            <div class="no-discount-price step-2 report"><sup>$</sup>349</div>
                                            <div class="text-block-436 report"><sup>$</sup>{{ getPrice }}</div>
                                        </div>
                                    </div>
                                    <div class="summary-entry">
                                        <div class="div-block-1541"><img src="@/assets/images/icon_doctor-check.svg"
                                                loading="lazy" alt="" class="image-278 report">
                                            <div class="text-block-435 report">Doctor Support<br>and Monitoring</div>
                                        </div>
                                        <div class="item-price">
                                            <div class="no-discount-price step-2 report"><sup>$</sup>99</div>
                                            <div class="text-block-436 report">Free</div>
                                        </div>
                                    </div>
                                    <div class="summary-entry">
                                        <div class="div-block-1541"><img src="@/assets/images/icon_testing-check.svg"
                                                loading="lazy" alt="" class="image-278 report">
                                            <div class="text-block-435 report">Metabolic testing and blood work included
                                            </div>
                                        </div>
                                        <div class="item-price">
                                            <div class="no-discount-price step-2 report"><sup>$</sup>75</div>
                                            <div class="text-block-436 report">Free</div>
                                        </div>
                                    </div>
                                    <div class="summary-entry">
                                        <div class="div-block-1541"><img src="@/assets/images/icon_delivery-check.svg"
                                                loading="lazy" alt="" class="image-278 report">
                                            <div class="text-block-435 report">Express Shipping</div>
                                        </div>
                                        <div class="item-price">
                                            <div class="no-discount-price step-2 report"><sup>$</sup>40</div>
                                            <div class="text-block-436 report">Free</div>
                                        </div>
                                    </div>
                                    <div class="savings-div"><img src="@/assets/images/party-popper.png" loading="lazy"
                                            alt="" class="image-279">
                                        
                                        <div class="text-block-438 report">You saved <sup>$</sup>{{214 + discount}} on your order!
                                        </div>
                                    </div>
                                    <a href="#" @click="pushToRoute($event)" class="link-block-34 w-inline-block">
                                        <div class="text-block-576">Get Started Today -&gt;</div>
                                        <div class="text-block-577">Guaranteed Weight Loss or Money Back</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="div-block-1406">
                        <div class="div-block-1407"><img src="@/assets/images/merris-_-before--after.jpg" loading="lazy"
                                sizes="(max-width: 479px) 75vw, (max-width: 767px) 24vw, (max-width: 991px) 26vw, 30vw"
                                srcset="@/assets/images/merris-_-before--after-p-500.jpg 500w, @/assets/images/merris-_-before--after-p-800.jpg 800w, @/assets/images/merris-_-before--after-p-1080.jpg 1080w, @/assets/images/merris-_-before--after-p-1600.jpg 1600w, @/assets/images/merris-_-before--after-p-2000.jpg 2000w, @/assets/images/merris-_-before--after.jpg 2149w"
                                alt="" class="image-286"></div>
                        <div class="div-block-1408"><img src="@/assets/images/icon_stars.svg" loading="lazy" alt=""
                                class="image-287">
                            <div class="text-block-471">Yara helped me achieve weight goals I never thought possible.
                            </div>
                            <p class="paragraph-98">Even as a nutritionist I still struggled to lose weight with diet
                                alone. With GLP1s it was a matter of weeks before the weight started dropping off.</p>
                            <div class="text-block-472">Merris, lost 55 lbs.</div>
                        </div>
                    </div>
                    <div class="div-block-1413">
                        <div class="text-block-475">What You can Expect</div>
                        <p class="paragraph-99">Join the millions of Americans losing weight with Semaglutide and
                            <strong>start seeing results in as little as 4 weeks!</strong>
                        </p>
                        <div class="div-block-1409">
                            <div class="div-block-1410">
                                <div class="div-block-1412">
                                    <div class="text-block-473">Lose weight</div>
                                    <div class="text-block-474">On average, Semaglutide users <span
                                            class="text-span-97">lose around 16%</span> of their body weight</div>
                                </div>
                                <div class="div-block-1412">
                                    <div class="text-block-473">Feel fuller, longer</div>
                                    <div class="text-block-474">Semaglutide <span class="text-span-97">suppresses
                                            appetite</span> and curbs food cravings**</div>
                                </div>
                            </div>
                            <div class="div-block-1410">
                                <div class="div-block-1412">
                                    <div class="text-block-473">Reduce cholesterol</div>
                                    <div class="text-block-474">Semaglutide has been shown to <span
                                            class="text-span-97">reduce total cholesterol, </span> LDL cholesterol &amp;
                                        triglycerides**</div>
                                </div>
                                <div class="div-block-1412">
                                    <div class="text-block-473">Improve heart health</div>
                                    <div class="text-block-474"><span class="text-span-97">Prevent heart attacks</span>
                                        and reduce the risk of major cardiac events***</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="#" @click="pushToRoute($event)" class="link-block-34 _3 w-inline-block">
                        <div class="text-block-576">Choose Your Program -&gt;</div>
                        <div class="text-block-577">Medications 80% Less than Name Brands.</div>
                    </a>
                    <div class="div-block-1406">
                        <div class="div-block-1407"><img src="@/assets/images/johnna_ba.png" loading="lazy"
                                sizes="(max-width: 479px) 75vw, (max-width: 767px) 24vw, (max-width: 991px) 26vw, 30vw"
                                srcset="@/assets/images/johnna_ba-p-500.png 500w, @/assets/images/johnna_ba-p-800.png 800w, @/assets/images/johnna_ba-p-1080.png 1080w, @/assets/images/johnna_ba.png 1182w"
                                alt="" class="image-286"></div>
                        <div class="div-block-1408"><img src="@/assets/images/icon_stars.svg" loading="lazy" alt=""
                                class="image-287">
                            <div class="text-block-471">I couldn&#x27;t imagine life without my GLP weekly routine!
                            </div>
                            <p class="paragraph-98">&quot;I went from 357 pounds all the way to to 145 pounds...all
                                thanks to a healthier diet, exercise and most import my weekly injection!&quot;</p>
                            <div class="text-block-472">Johnna, lost 212 lbs.</div>
                        </div>
                    </div>
                    <a href="#" @click="pushToRoute($event)" class="link-block-34 _3 w-inline-block">
                        <div class="text-block-576">Get Started Today -&gt;</div>
                        <div class="text-block-577">Click to change your life.</div>
                    </a>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
export default {
    data() {
        return {
            scrollAmount: 225,
            people: {
                "first_name": "",
                "current_weight": "",
                "desired_weight": ""
            },
            videos: [
                {
                    src: "/videos/GOOD-Weight-Loss274-transcode.mp4",
                    srcMp4: "/videos/GOOD-Weight-Loss274-transcode.mp4",
                    srcWebm: "/videos/GOOD-Weight-Loss274-transcode.webm",
                    poster: "/videos/GOOD-Weight-Loss274-poster-00001.jpg",
                    isPlaying: false,
                },
                {
                    src: "/videos/GOOD-Weight-Loss289-transcode.mp4",
                    srcMp4: "/videos/GOOD-Weight-Loss289-transcode.mp4",
                    srcWebm: "/videos/GOOD-Weight-Loss289-transcode.webm",
                    poster: "/videos/GOOD-Weight-Loss289-poster-00001.jpg",
                    isPlaying: false,
                },
                {
                    src: "/videos/GOOD-Weight-Loss278-transcode.mp4",
                    srcMp4: "/videos/GOOD-Weight-Loss278-transcode.mp4",
                    srcWebm: "/videos/GOOD-Weight-Loss278-transcode.webm",
                    poster: "/videos/GOOD-Weight-Loss278-poster-00001.jpg",
                    isPlaying: false,
                },
                {
                    src: "/videos/GOOD-Weight-Loss279-transcode.mp4",
                    srcMp4: "/videos/GOOD-Weight-Loss279-transcode.mp4",
                    srcWebm: "/videos/GOOD-Weight-Loss279-transcode.webm",
                    poster: "/videos/GOOD-Weight-Loss279-poster-00001.jpg",
                    isPlaying: false,
                },
                {
                    src: "/videos/GOOD-Weight-Loss288-transcode.mp4",
                    srcMp4: "/videos/GOOD-Weight-Loss288-transcode.mp4",
                    srcWebm: "/videos/GOOD-Weight-Loss288-transcode.webm",
                    poster: "/videos/GOOD-Weight-Loss288-poster-00001.jpg",
                    isPlaying: false,
                },
                {
                    src: "/videos/GOOD-Weight-Loss271-transcode.mp4",
                    srcMp4: "/videos/GOOD-Weight-Loss271-transcode.mp4",
                    srcWebm: "/videos/GOOD-Weight-Loss271-transcode.webm",
                    poster: "/videos/GOOD-Weight-Loss271-poster-00001.jpg",
                    isPlaying: false,
                },
            ],
        };
    },
    computed: {
        
        isDiscountOffer() {
            return true;
        },
        getPrice() {
            let discountedPrice = 349 - this.discount;
                       
            if (discountedPrice % 1 !== 0) {
                discountedPrice = Math.round(discountedPrice * 100) / 100;
            }
            return discountedPrice;
        },
        discount() {
            if(this.$route.query.offer=="ninetynine"){
                return 250
            }else if(this.$route.query.offer=="oneforty"){
                return 150
            }
            return 0
        }
    },
    mounted() {
        this.getDetails();
        this.initializeCountdownTimer();
        const slider = this.$el.querySelector('.w-slider');
        slider.addEventListener('touchstart', this.handleTouchStart);
        slider.addEventListener('touchmove', this.handleTouchMove);
        slider.addEventListener('touchend', this.handleTouchEnd);
    },
    methods: {
        initializeCountdownTimer() {
            if (this.isDiscountOffer) {
                if (typeof window.CountdownTimer !== 'undefined') {
                    let offer = "discount"+this.discount;
                    const countdownTimer = new window.CountdownTimer({
                        selector: '#sytim-timer',
                        redirectUrl: '',
                        message: '',
                        offer: offer,
                        style: 'inline',
                        identifier: this.$route.query.id,
                        containerId: 'hereitwill',
                        fontColor: '#000',
                        backgroundColor: 'transparent',
                        mediaGap: "1rem"
                    });
                    countdownTimer.startTimer();
                } else {
                    console.error('CountdownTimer is not defined.');
                }
            }
        },
        getDetails() {
            this.people.first_name = this.$route.query.name;
            this.people.weight = this.$route.query.weight;
            this.people.desired_weight = this.$route.query.goal;
        },
        handleTouchStart(event) {
            this.startX = event.touches[0].clientX;
        },
        handleTouchMove(event) {
            this.currentX = event.touches[0].clientX;
        },
        handleTouchEnd() {
            const deltaX = this.currentX - this.startX;
            if (Math.abs(deltaX) > 50) {
                if (deltaX > 0) {
                    this.scrollLeft();
                } else {
                    this.scrollRight();
                }
            }
        },
        scrollLeft() {
            const container = this.$refs.carouselContent;
            container.scrollBy({
                left: -this.scrollAmount,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            const container = this.$refs.carouselContent;
            container.scrollBy({
                left: this.scrollAmount,
                behavior: 'smooth'
            });
        },
        pushToRoute(event) {
            event.preventDefault();
            if (this.$route.query.offer=="ninetynine" || this.$route.query.offer=="oneforty") {
                this.$router.push({
                    name: 'CheckoutOffer',
                    query: {
                        "health_id": this.$route.query.health_id,
                        "id": this.$route.query.id,
                        "name": this.$route.query.name,
                        "phase": 1,
                        "plan": "Semaglutide",
                        "pay_terms": 1,
                        "state": this.$route.query.state,
                        "aegate" : "Mjk5",
                        "offer": this.$route.query.offer
                    }
                });
            }else {
                this.$router.push({
                    name: 'CheckoutForm',
                    query: {
                        "health_id": this.$route.query.health_id,
                        "id": this.$route.query.id,
                        "name": this.$route.query.name,
                        "phase": this.$route.query.phase,
                        "plan": this.$route.query.plan,
                        "state": this.$route.query.state,
                        "discount": this.$route.query.discount ?? 100,
                    }
                });
            }
        },
        togglePlayPause(index) {
            const videoElement = this.$refs[`video-${index}`][0];
            if (this.videos[index].isPlaying) {
                videoElement.pause();
            } else {
                videoElement.play();
            }
        },
        updatePlayState(index, isPlaying) {
            this.$set(this.videos, index, { ...this.videos[index], isPlaying });
        },
        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
    },
    created(){
        this.scrolltoTop();
        try{
            window.obApi('track', 'Registration');
        }catch(error){
            console.log(error);
        }
    }
};
</script>
<style scoped>
[data-wf-bgvideo-fallback-img] {
    display: none;
}

@media (prefers-reduced-motion: reduce) {
    [data-wf-bgvideo-fallback-img] {
        position: absolute;
        z-index: -100;
        display: inline-block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


[data-wf-bgvideo-fallback-img] {
    display: none;
}

@media (prefers-reduced-motion: reduce) {
    [data-wf-bgvideo-fallback-img] {
        position: absolute;
        z-index: -100;
        display: inline-block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


[data-wf-bgvideo-fallback-img] {
    display: none;
}

@media (prefers-reduced-motion: reduce) {
    [data-wf-bgvideo-fallback-img] {
        position: absolute;
        z-index: -100;
        display: inline-block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

[data-wf-bgvideo-fallback-img] {
    display: none;
}

@media (prefers-reduced-motion: reduce) {
    [data-wf-bgvideo-fallback-img] {
        position: absolute;
        z-index: -100;
        display: inline-block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

[data-wf-bgvideo-fallback-img] {
    display: none;
}

@media (prefers-reduced-motion: reduce) {
    [data-wf-bgvideo-fallback-img] {
        position: absolute;
        z-index: -100;
        display: inline-block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
</style>