import { render, staticRenderFns } from "./DiscountPagePreSell.vue?vue&type=template&id=0cfe8555&scoped=true"
import script from "./DiscountPagePreSell.vue?vue&type=script&lang=js"
export * from "./DiscountPagePreSell.vue?vue&type=script&lang=js"
import style0 from "./DiscountPagePreSell.vue?vue&type=style&index=0&id=0cfe8555&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cfe8555",
  null
  
)

export default component.exports