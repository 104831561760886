<template>
    <div>
        <div class="popup-modal _1" style="display: block;">
            <div>
                <div class="next-steps-box checkout">
                    <div class="next-step-header-div">
                        <div class="text-block-715 checkout">Select your state.</div>
                    </div>
                    <div class="step-entry-div checkout">
                        <div class="div-block-1663">
                            <div class="text-block-714 checkout">
                                <form @submit.prevent="setState">
                                    <div class="preview"  style="display:flex;justify-content: center; padding-top:25px">
                                        <select v-model="state_sel" required class="text-field-40 height w-input" style="width:80%">
                                            <option value="" disabled selected>State...</option>
                                            <option v-for="(value, key) in allstate" :value="value" :key="value">{{ key }} </option>
                                        </select>
                                    </div>
                                    <div class="footer-button">
                                        <div class="div-block-1120"  style="display:flex;justify-content: center;">
                                            <input type="submit" data-wait="Please wait..." class="submit-button-2 b email w-button" value="Schedule -&gt;">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import state from "../utils/state.json"
    export default {
        data() {
            return {
                allstate: state,
                state_sel: "",
            }
        },
        methods: {
            setState(){
                this.$emit('setState', this.state_sel);
            }
        },
    }
    </script>
    
    
    