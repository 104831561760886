<template>
    <div>
        <loading v-if="loading" />
        <div>
            <div class="form-3-header-div">
                <div class="div-block-966">
                    <a href="#" class="w-inline-block"><img loading="lazy" :src="mainlogo" alt="" class="image-30"></a>
                    <div class="div-block-967">
                        <div class="text-block-152 top">STEP</div>
                        <div class="text-block-151">{{ questionId }} <span class="text-span-72">of</span> 4</div>
                        <div class="text-block-152 bottom">COMPLETE</div>
                    </div>
                </div>
            </div>
            <div class="div-block-181">
                <div class="girl-div"></div>
            </div>
        </div>
        <div>
            <div class="div-block-225">
                <div class="slide-1" v-bind:class="{ 'hide-element-ques': questionId !== 1 }">
                    <div class="div-block-226">
                        <h1 class="heading-39 form"><span class="text-span-69">Thank You for your payment!</span><br>You made the right choice {{people.first_name}}! <br/> We need some more info to send over to your doctor before your appointment</h1>
                        
                        <div class="div-block-1102">
                            <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_pencil2')" alt=""
                                    class="image-186">
                                <div class="text-block-221"><strong>Quick Eligibility Check</strong> (1 min)</div>
                            </div>
                            <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_meds')" alt=""
                                    class="image-186">
                                <div class="text-block-221"><strong>Short Medical Form </strong> (3 min)</div>
                            </div>
                            <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_support')" alt=""
                                    class="image-186">
                                <div class="text-block-221 long"><strong>Schedule Dr. Visit.</strong></div>
                            </div>
                            <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_best')" alt=""
                                    class="image-186">
                                <div class="text-block-221 long"><strong>Get Meds in 3 Days.</strong></div>
                            </div>
                        </div>
                        <a href="#" class="form-button-1 w-button" @click.prevent="questionId = 2">YES. LET&#x27;S DO
                            IT!</a>
                    </div>
                </div>

                <div class="slide6" v-bind:class="{ 'hide-element-ques': questionId !== 2 }">
                    <h1 class="heading-39 gender">What was your gender at birth?<br></h1>
                    <div class="form-block-3 w-form">
                        <form id="email-form" name="email-form" class="form-3">
                            <div class="div-block-965 gender">
                                <a href="#" @click.prevent="setValueForGender('Male')"
                                    class="link-block-23 w-inline-block"><img src="@/assets/images/Male.svg"
                                        loading="lazy" alt="" class="image-274">
                                    <div class="text-block-419">Male</div>
                                </a>
                                <a href="#" @click.prevent="setValueForGender('Female')"
                                    class="link-block-23 w-inline-block"><img src="@/assets/images/Female.svg"
                                        loading="lazy" alt="" class="image-274">
                                    <div class="text-block-419">Female</div>
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="slide12" v-bind:class="{ 'hide-element-ques': questionId !== 3 }">
                    <h1 class="heading-39">What is your height and weight?</h1>
                    <p class="paragraph-79">We&#x27;ll use this to determine your Body mass Index (BMI) for diagnosis.
                        remember,
                        BMI is a measure of size - not health</p>
                    <div class="form-block-3 b w-form">
                        <form id="email-form" name="email-form" @submit.prevent="calculateBMI()" class="form-3 b">
                            <div class="div-block-965 b">
                                <div class="div-block-374 mobile-entry form _2 height top">
                                    <input class="text-field-40 height w-input" pattern="[0-9]*"
                                        v-model="people.height.ft" maxlength="2" name="feet" data-name="Feet"
                                        placeholder="Feet" type="text" id="feet" required>
                                    <div class="text-block-343">ft.</div><input class="text-field-40 height w-input"
                                        v-model="people.height.inch" pattern="[0-9]*" maxlength="2" name="inches"
                                        data-name="Inches" placeholder="Inches" type="text" id="inches" required>
                                    <div class="text-block-344">in.</div>
                                </div>
                                <div class="div-block-374 mobile-entry form _2 weight"><input
                                        class="text-field-40 weight w-input" v-model="people.weight" pattern="[0-9]*"
                                        maxlength="4" name="weight-2" data-name="Weight 2"
                                        placeholder="Weight in pounds" type="text" id="weight-2" required>
                                    <div class="text-block-343 weight">lbs.</div>
                                </div>
                            </div><input type="submit" data-wait="Please wait..." class="submit-button-2 b w-button"
                                value="Next -&gt;">
                        </form>
                    </div>
                    <div class="back-link-div">
                        <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                            <div class="text-block-146">&lt; Go Back</div>
                        </a>
                    </div>
                </div>
                <div class="slide4" v-bind:class="{ 'hide-element-ques': questionId !== 4 }">
                    <h1 class="heading-39">Do you have any of these medical conditions?</h1>
                    <div class="w-form-fail" style="display:block;" v-if="errorReason">
                            <div>{{errorMessage}}</div>
                        </div>
                    <div class="form-block-3 w-form">
                        
                        <form id="email-form" name="email-form" @submit.prevent="checkMedicalCondition()"
                            class="form-3">
                            <div class="disclaimer-box">
                                <div class="div-block-1358">
                                    <div class="text-block-418">!</div>
                                </div>
                                <p class="paragraph-93">Any of these complications may disqualify you from weight loss
                                    medication
                                    approval. <strong>&quot;None&quot; is the best answer.</strong></p>
                            </div>
                            <a href="#" @click.prevent="setMedicalCondition()"
                                class="button-55 full-width w-button">None (Best Answer) <span
                                    class="text-span-92">-&gt;</span></a>
                            <div class="div-block-965" v-if="people.gender == 'Female'"><label
                                    class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        v-model="medical_condition" value="Pregnant"
                                        class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label"
                                        for="checkbox-2">Pregnant</span></label><label
                                    class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        v-model="medical_condition" value="Breast Feeding"
                                        class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label"
                                        for="checkbox-2">Breast Feeding</span></label></div>
                            <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        value="Type 2 diabetes ON INSULIN" v-model="medical_condition"
                                        class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label"
                                        for="checkbox-2">Type
                                        2 diabetes ON
                                        INSULIN</span></label><label class="w-checkbox checkbox-field-4"><input
                                        type="checkbox" value="Type 1 diabetes" v-model="medical_condition"
                                        class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label"
                                        for="checkbox-2">Type 1 diabetes</span></label></div>
                            <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        value="Diabetic retinopathy" v-model="medical_condition"
                                        class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label"
                                        for="checkbox-2">Diabetic retinopathy</span></label><label
                                    class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        v-model="medical_condition" value="End-stage liver disease (cirrhosis)"
                                        class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label"
                                        for="checkbox-2">End-stage liver disease
                                        (cirrhosis)</span></label></div>
                            <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        value="End-stage kidney disease (on or about to be on dialysis)"
                                        v-model="medical_condition" class="w-checkbox-input checkbox"><span
                                        class="checkbox-label-4 w-form-label" for="checkbox-2">End-stage kidney disease
                                        (on or about to be
                                        on dialysis)</span></label><label class="w-checkbox checkbox-field-4"><input
                                        type="checkbox" value="Current or prior eating disorder"
                                        v-model="medical_condition" class="w-checkbox-input checkbox"><span
                                        class="checkbox-label-4 w-form-label" for="checkbox-2">Current or prior eating
                                        disorder
                                        (anorexia/bulimia)</span></label></div>
                            <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        value="Current suicidal thoughts and/or prior suicidal attempt"
                                        v-model="medical_condition" class="w-checkbox-input checkbox"><span
                                        class="checkbox-label-4 w-form-label" for="checkbox-2">Current suicidal thoughts
                                        and/or prior suicidal attempt</span></label><label
                                    class="w-checkbox checkbox-field-4">
                                    <input type="checkbox"
                                        value="History of organ transplant on anti-rejection medication"
                                        v-model="medical_condition" class="w-checkbox-input checkbox"><span
                                        class="checkbox-label-4 w-form-label" for="checkbox-2">History of organ
                                        transplant on anti-rejection
                                        medication</span></label></div>
                            <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        value="Severe gastrointestinal condition" v-model="medical_condition"
                                        class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label"
                                        for="checkbox-2">Severe gastrointestinal condition
                                        (gastroparesis, blockage, inflammatory bowel disease)</span></label><label
                                    class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        value="Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2"
                                        v-model="medical_condition" class="w-checkbox-input checkbox"><span
                                        class="checkbox-label-4 w-form-label" for="checkbox-2">Personal or family
                                        history of thyroid
                                        cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine
                                        neoplasia syndrome
                                        type 2</span></label></div>
                            <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        v-model="medical_condition" class="w-checkbox-input checkbox"
                                        value="History of or current pancreatitis"><span
                                        class="checkbox-label-4 w-form-label" for="checkbox-2"><strong>History of or
                                            current
                                            pancreatitis</strong></span></label>
                                <label class="w-checkbox checkbox-field-4"><input type="checkbox"
                                        v-model="medical_condition" class="w-checkbox-input checkbox"
                                        value="Had prior bariatric (weight loss) surgery or any abdominal/pelvic surgeries"><span
                                        class="checkbox-label-4 w-form-label" for="checkbox-2"><strong>Had prior
                                            bariatric (weight loss)
                                            surgery or any abdominal/pelvic surgeries</strong></span></label>
                            </div><input type="submit" data-wait="Please wait..." class="submit-button-2 w-button"
                                value="Next -&gt;">
                        </form>
                        
                    </div>
                    <div class="back-link-div">
                        <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                            <div class="text-block-146">&lt; Go Back</div>
                        </a>
                    </div>
                </div>
                <div class="slide-1" v-bind:class="{ 'hide-element-ques': questionId !== 20 }">
                    <div class="div-block-226"><img loading="lazy" src="@/assets/images/icon_sad-copy.svg" alt=""
                            class="image-205">
                        <h1 class="heading-39 form">We&#x27;re Sorry</h1>
                        <p class="paragraph-67">Based on the information you provided, you currently do not qualify for
                            our weight
                            loss programs.</p>
                        <div class="text-block-149">All we will need is some basic info before we schedule your teledoc
                            appointment.
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="included-space" :style="heightFooter"></div>
    </div>
</template>



<script>
import loading from '../../common/loadingScreen.vue'
import state from "../../utils/state.json"

export default {
    name: 'HelloWorld',
    props: ['baseUrl', 'mainlogo', 'iconSVGColor'],
    components: {
        'loading': loading
    },
    data() {
        return {
            allstate: state,
            loading: false,
            questionId:1,
            optionsWidth: `calc(50% - 10px)`,
            formResponse:[],
            currentQuestionIndex: 0,
            medical_condition: [],
            tried_in_past: [],
            errorReason:false,
            errorMessage:'',
            people: {
                first_name:'',
                id: '',
                health_id: '',
                gender: 'Female',
                weight: '',
                state:'',
                dob:'',
                height: {
                    ft: '',
                    inch: ''
                },
            },
            options_medical: [
                "Type 2 diabetes ON INSULIN",
                "Type 1 diabetes",
                "Diabetic retinopathy",
                "End-stage liver disease (cirrhosis)",
                "End-stage kidney disease (on or about to be on dialysis)",
                "Current or prior eating disorder (anorexia/bulimia)",
                "Current suicidal thoughts and/or prior suicidal attempt",
                "History of organ transplant on anti-rejection medication",
                "Severe gastrointestinal condition (gastroparesis, blockage, inflammatory bowel disease)",
                "Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2",
                "History of or current pancreatitis",
                "None of the above"
            ],
            selectedOptions: [],
        }
    },
    computed: {
        heightFooter() {
            return 'height: 180px;';
        },
    },
    watch: {
        questionId(newVal) {
            this.scrolltoTop();
            if (newVal >= 1) {
                this.checkQuestionId();
            }
        }
    },
    mounted() {
        this.checkUrlParameters();
    },
    methods: {
        setMedicalCondition() {
            this.loading = true;
            this.errorReason = false,
            window.axios.post(this.baseUrl + '/qualified-customer', this.people).then((response) => {
                if (response.data.status == 'success') {
                    this.people.id = response.data.user_id;
                    this.people.health_id = response.data.health_id;
                    this.updateShipping();
                }else {
                    this.errorReason = true;
                    this.errorMessage = response.data.message;
                    this.loading = false;
                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        updateShipping(){
            window.axios.post(this.baseUrl + '/update-matrics', this.people);
            let utm_source = this.$route.query.utm_source?? this.formResponse.utm_source;
            window.axios.post(this.baseUrl + '/register-affiliate', {
                    "id": this.people.id,
                    "affiliate_id": this.$route.query.utm_campaign,
                    "utm_source": utm_source,
                    "tid": this.formResponse.tid,
                    "discount": this.formResponse.discount,
                    "med": "Semaglutide",
                    "state": this.formResponse.state,
                    "amount": this.formResponse.amount,
                    "pay_terms": 1,
                    "userAgent":  navigator.userAgent,
                    "ip_address": JSON.parse(localStorage.getItem("ip_address")),
                    "callcenter_source": null,
                    "forward_by_call": null,
            });
            this.updatePeopleDetails();
            
            this.$router.push({
                name: 'AfterCheckout',
                query: {
                    "health_id": this.people.health_id,
                    "id": this.people.id,
                    "plan": this.people.med,
                    "state": this.people.state,
                }
            });
        },
        updatePeopleDetails(){
            localStorage.setItem("formResponse", JSON.stringify(this.people));
            window.axios.post(this.baseUrl + '/update-formresponse', this.people);
        },
        getIconPath(path) {
            const images = require.context('@/assets/images', false, /\.svg$/);
            return images(`./${path}${this.iconSVGColor}.svg`);
        },
        checkUrlParameters() {
            if (this.$route.query.id) {
                this.people.id = this.$route.query.id;
                this.people.state = this.$route.query.state;
                this.people.first_name = this.$route.query.first_name;
                window.axios.get(this.baseUrl + '/get-form?id=' + this.$route.query.id, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*"
                    }
                }).then((response) => {
                    this.formResponse = response.data.form.payload;
                    this.people.state = this.formResponse.state;
                    this.people.first_name = this.formResponse.first_name;
                    this.people.email = this.formResponse.email;
                    if (typeof this.formResponse.dob === 'object' && this.formResponse.dob !== null && !Array.isArray(this.formResponse.dob)) {
                         this.people.dob = this.formResponse.dob.yyyy + "-" + this.formResponse.dob.mm + "-" + this.formResponse.dob.dd
                    }else{
                        let dob = this.formResponse.dob.split("/");
                        this.people.dob = dob[2]+"-"+dob[0]+"-"+dob[1];
                    }
                }).catch((error) => {
                    console.log(error)
                });

            } else {
                this.$router.push({
                    name: 'QualifyingQuestion',
                });
            }
        },

        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },

        calculateBMI() {
            var heightInInches = parseInt(this.people.height.ft * 12) + parseInt(this.people.height.inch);
            let cal = (this.people.weight * 703);
            let heigh = (heightInInches * heightInInches);
            var bmi = cal / heigh;
            if (bmi < 27) {
                this.questionId = 20;
            } else {
                this.questionId++;
            }
        },
        setValueForGender(val) {
            let vm = this;
            vm.people.gender = val;
            setTimeout(function () {
                vm.questionId++;
            }, 200);

        },
        isSelected(option) {
            return this.selectedOptions.includes(option);
        },
        toggleOption(option) {
            if (option === "None of the above") {
                this.selectedOptions = [option];
            } else {
                const index = this.selectedOptions.indexOf(option);
                if (index !== -1) {
                    this.selectedOptions.splice(index, 1);
                } else {
                    this.selectedOptions.push(option);
                }
                if (this.selectedOptions.includes("None of the above")) {
                    const index = this.selectedOptions.indexOf("None of the above");
                    this.selectedOptions.splice(index, 1);
                }
            }
        },
        checkMedicalCondition() {
            this.questionId = 20;
        },
        checkQuestionId() {
            if (this.questionId == 20) {
                window.axios.post(this.baseUrl + '/mark-rejected-refund', this.people);
            }
        },
    },

}
</script>

<style scoped>
.text-danger {
    color: red !important;
    border: solid 1px red !important;
}
.hide-element-ques{
    display: none ;
}
</style>
