<template>
<div>
    <div id="mygalModal" aria-hidden="true" class="modal fade move-up in intro-modal modal-for-gal" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="new-modal">
                    <div class="modal_down bacground_sdo">
                        <div class="intro-modal-body">
                            <div class="trial-details" v-if="el==1">
                                <div class="preview" style="padding-top: 35px !important;display:flex;justify-content: center;">
                                    <span style="font-size: 20px;">Why are you cancelling?</span>
                                </div>
                                <div class="footer-button" style="padding-top: 35px !important;">
                                    <div class="div-block-965 options-container">
                                        <div class="choice-div radio b w-checkbox-med-options" v-for="(option, index) in options_array" :key="index" @click="setReason(option)">
                                            <label class="radio-button-field w-radio" :id="'radio-' + index">
                                                <span class="radio-button-label w-form-label" style="font-size:16px;" :for="'radio-' + index">{{ option }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="trial-details" v-if="el==2">
                                <div class="preview" style="padding-top: 35px !important;display:flex;justify-content: center;">
                                    <span style="font-size: 20px;">Would you recommend Yara?</span>
                                </div>
                                <div class="footer-button" style="padding-top: 35px !important;">
                                    <div class="div-block-1120">
                                        <a href="#" @click="setReccomend($event, 'yes')" class="button-44 w-button dash-button">
                                            <span v-if="submitted"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                    <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                    </circle>
                                                </svg></span>
                                            <span v-else>Yes</span></a>
                                        <a href="#" @click="setReccomend($event, 'no')" class="button-45 w-button dash-button">Close</a>
                                    </div>
                                </div>
                            </div>
                            <div class="trial-details" v-if="el==3">
                                <div class="preview" style="padding-top: 35px !important;display:flex;justify-content: center;">
                                    <span style="font-size: 20px;">Are you sure you want to cancel?</span>
                                </div>
                                <div class="footer-button" style="padding-top: 35px !important;">
                                    <div class="div-block-1120">
                                        <a href="#" @click="cancelSubscription($event)" class="button-44 w-button dash-button">
                                            <span v-if="submitted"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                    <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                                    </circle>
                                                </svg></span>
                                            <span v-else>Yes</span></a>
                                        <a href="#" @click="CloseDialog($event)" class="button-45 w-button dash-button">Close</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ["baseUrl"],
    data() {
        return {
            mykey: 1,
            token: null,
            errorReason: '',
            submitted: false,
            reason: '',
            reccomend:'',
            el: 1,
            options_array: [
                "It is too expensive",
                "I lost enough weight",
                "The prescription did not work",
                "I do not like the injection method",
                "Too many side effects",
                "Other"
            ]
        }
    },
    mounted() {

    },
    methods: {
        setReason(val) {
            let vm = this;
            this.reason = val;
            setTimeout(function () {
                vm.el++;
            }, 20);
        },
        setReccomend(event, val) {
            event.preventDefault();
            let vm = this;
            this.reccomend = val;
            setTimeout(function () {
                vm.el++;
            }, 20);
        },
        cancelSubscription(event) {
            event.preventDefault();
            this.submitted = true;
            window.axios.post(this.baseUrl + '/cancle-subs',{
                reason: this.reason,
                reccomend : this.reccomend
            }).then(response => {
                if (response.data.status == 'error') {
                    this.$emit("ShowToastForCancelError", response.data.message);
                    console.log("error");
                } else {
                    this.$emit("ShowToastForCancel");
                }
            });
        },
        CloseDialog(event) {
            event.preventDefault();
            this.$emit("CloseBox");
        },
    },
    created() {
        //this.setUpStripe();
    }
}
</script>

<style scoped>
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

}

.intro-modal-body {
    justify-content: center;
}

.intro-modal-heading {
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.modal-content {
    background-color: #fefefe;
    border-radius: 10px;
    max-width: 468px;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.dash-button {
    height: 45px;
}

.options-container {
    display: flex;
    flex-wrap: wrap;
}
</style>
