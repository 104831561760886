import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
window.axios = require('axios');
require('axios');
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
import store from './store'

import './assets/formStyle/variables.css';
import './assets/formStyle/normalize.css';
import './assets/formStyle/webflow.css';
import './assets/formStyle/yarapages.webflow.css';
import './assets/formStyle/only_var.css';

window.axios.interceptors.request.use(
  (config) => {
    let token = store.getters.authToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

window.axios.interceptors.response.use(response => {
  return response;
}, error => {
  try{
    
    return error;
  }catch(e){
    console.log(e)
  }
  
});

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

