<template>
<div>
    <model-window v-if="showModel" @close="showModel = false" />
    <div>
        <div class="form-3-header-div">
            <div class="div-block-966">
                <a href="#" class="w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg" alt="" class="image-30"></a>
            </div>
        </div>
    </div>
    <div>
        <div class="div-block-225">
            <div class="slide-0" v-if="questionId == 1">
                <div class="w-form">
                    <form @submit.prevent="questionId = 2">
                        <div class="_0_herodiv"><img src="@/assets/images/stepsimage_teledoc.png" loading="lazy" sizes="(max-width: 479px) 28vw, 15vw" srcset="@/assets/images/stepsimage_teledoc-p-500.png 500w, @/assets/images/stepsimage_teledoc.png 641w" alt="" class="image-366">
                            <div class="text-block-678">Welcome to Yara!</div>
                            <p class="paragraph-122">The following questions will will be used by a board certified physician
                                licensed
                                in your state to help create a treatment plan for you. <strong>Please answer all questions
                                    accurately.</strong></p>
                            <div class="disclaimer-box-div"><label class="w-checkbox checkbox-field-16"><input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input checkbox-14" checked required><span class="checkbox-label-14 w-form-label" for="checkbox-3">I Agree to Yara&#x27;s <a href="https://joinyara.com/terms" target="_blank" class="link-44">Terms</a>, <a href="https://joinyara.com/privacy-policy-new" target="_blank" class="link-44">Privacy
                                            Policy</a> and
                                        consent to <a href="https://joinyara.com/tele-health-consent" class="link-44" target="_blank">Telehealth</a></span></label></div>
                            <div class="form-button-div" style="margin-bottom: 30px;"><input type="submit" data-wait="Please wait..." class="submit-button-2 update w-button" value="Start my FREE visit -&gt;"></div>

                            <div class="div-block-1657">
                                <div class="div-block-1658"><img src="@/assets/images/icon_testing-check-copy.svg" loading="lazy" alt="" class="image-367">
                                    <div class="text-block-679">Free online medical evaluation</div>
                                </div>
                                <div class="div-block-1658"><img src="@/assets/images/icon_doctor-check-copy.svg" loading="lazy" alt="" class="image-367">
                                    <div class="text-block-679">Board-certified physicians</div>
                                </div>
                                <div class="div-block-1658"><img src="@/assets/images/icon_delivery-check-copy.svg" loading="lazy" alt="" class="image-367">
                                    <div class="text-block-679">Fast free shipping</div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
            </div>
            <div class="slide-2" v-if="questionId == 2">
                <div class="div-block-1659">
                    <div class="text-block-680">Let&#x27;s start with the Basics</div>
                </div>
                <div class="form-block-3 update w-form">
                    <form class="form-3 update" @submit.prevent="validateEmail">
                        <h1 class="heading-39 update a">Let&#x27;s make sure we are licensed in your state</h1>
                        <select id="State-4" name="State-4" data-name="State 4" @change="checkState" v-model="people.state" required class="select-field-3 form update w-select">
                            <option value="" disabled selected>State...</option>
                            <option v-for="(value, key) in allstate" :value="value" :key="value">{{ key }} </option>
                        </select>
                        <h1 class="heading-39 update a">Who do I have the pleasure of talking to?</h1>
                        <div class="div-block-965 update">
                            <input class="text-field-33 update w-input" maxlength="256" name="name" data-name="Name" placeholder="First Name" v-model="people.first_name" type="text" id="name" required>
                            <input class="text-field-33 update w-input" maxlength="256" v-model="people.last_name" name="Last-name" data-name="Last Name" placeholder="Last Name" type="text" id="Last-name" required>
                        </div>
                        <h1 class="heading-39 update">What was your gender at birth?</h1>
                        <div class="div-block-1660">
                            <label class="w-checkbox checkbox-field-17 male">
                                <input type="radio" value="Male" v-model="people.gender" id="redio-male" name="checkbox-4" data-name="Checkbox 4" class="w-checkbox-input checkbox-15">
                                <span class="checkbox-label-15 w-form-label" for="checkbox-4">Male</span>
                            </label>
                            <label class="w-checkbox checkbox-field-17 female">
                                <input type="radio" value="Female" id="redio-female" v-model="people.gender" data-name="Checkbox 4" class="w-checkbox-input checkbox-15">
                                <span class="checkbox-label-15 w-form-label" for="checkbox-4">Female</span>
                            </label>
                        </div>
                        <h1 class="heading-39 update">Please enter your email</h1>
                        <input v-model="people.email" class="text-field-33 email form update w-input" v-bind:class="{ 'text-danger': emailError }" maxlength="256" name="Email-2" data-name="Email 2" placeholder="Enter your email" type="email" id="Email-2" required>
                        <div class="disclaimer-box-div form">
                            <label class="w-checkbox checkbox-field-16 form">
                                <input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input checkbox-14" required checked>
                                <span class="checkbox-label-14 form w-form-label" for="checkbox-3">I agree to receive doctor
                                    correspondence, transactional and/or marketing messaging from Yara to the email address that I
                                    provided above.</span>
                            </label>
                        </div>
                        <h1 class="heading-39 update">Please enter your mobile number</h1>
                        <div class="phone-entry-div update">
                            <input class="phone-number-field _1 form update w-input" pattern="[0-9]*" maxlength="3" minlength="3" @keyup="focusNext" v-model="people.phone_part.part1" name="field-2" data-name="Field 2" placeholder="(000)" type="text" id="field-2" required>
                            -
                            <input class="phone-number-field form update w-input" v-model="people.phone_part.part2" pattern="[0-9]*" maxlength="3" minlength="3" @keyup="focusNext" name="field-2" data-name="Field 2" placeholder="000" type="text" id="field-2" required>
                            -
                            <input class="phone-number-field _2 form update w-input" pattern="[0-9]*" maxlength="4" minlength="4" @keyup="focusNext" name="field-2" v-model="people.phone_part.part3" data-name="Field 2" placeholder="0000" type="text" id="field-2" required>
                        </div>
                        <h1 class="heading-39 update">Can we send you text messages about your prescription?</h1>
                        <div class="text-block-681">(including tracking information and refill information)</div>
                        <div class="div-block-965 update bottom">
                            <label class="w-checkbox checkbox-field-4 update">
                                <input type="radio" name="checkbox-2" value="Yes" v-model="people.sms_opted" id="redio-2yes" data-name="Checkbox 2" class="w-checkbox-input checkbox" checked>
                                <span class="checkbox-label-4 w-form-label" for="checkbox-2">Yes</span>
                            </label>
                            <label class="w-checkbox checkbox-field-4 update">
                                <input type="radio" name="checkbox-2" id="redio-2no" value="No" v-model="people.sms_opted" data-name="Checkbox 2" class="w-checkbox-input checkbox">
                                <span class="checkbox-label-4 w-form-label" for="checkbox-2">No</span>
                            </label>
                        </div>
                        <div class="disclaimer-box-div form">
                            <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" value="">
                            <label class="w-checkbox checkbox-field-16 form">
                                <input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input checkbox-14" checked required>
                                <span class="checkbox-label-14 form w-form-label" for="checkbox-3">By checking this box, I also
                                    consent by
                                    electronic signature to receive phone calls and/or SMS text messages at the phone number(s) below,
                                    including my wireless number if prvided. I understand these calls may be generated using an
                                    automated
                                    technology, pre-recorded voices and that data rates may apply. I understand that my consent is not
                                    required to buy goods/services and I may opt out at any time to avoid receiving calls or SMS text
                                    messages.</span>
                            </label>
                        </div>
                        <input type="submit" data-wait="Please wait..." class="submit-button-2 w-button" value="Next -&gt;">
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail" style="display: block;" v-if="emailError">
                        <div>Please enter correct email.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide12" v-if="questionId == 3">
                <h1 class="heading-39">What is your height and weight?</h1>
                <p class="paragraph-79">We&#x27;ll use this to determine your Body mass Index (BMI) for diagnosis. remember,
                    BMI
                    is a measure of size - not health</p>
                <div class="form-block-3 b w-form">
                    <form class="form-3 b" @submit.prevent="calculateBMI">
                        <div class="div-block-965 b">
                            <div class="div-block-374 mobile-entry form _2 height top">
                                <input class="text-field-40 height w-input" maxlength="256" v-model="people.height.ft" name="feet" data-name="Feet" placeholder="Feet" type="text" id="feet" required>
                                <div class="text-block-343">ft.</div>
                                <input class="text-field-40 height update w-input" maxlength="256" v-model="people.height.inch" name="inches" data-name="Inches" placeholder="Inches" type="text" id="inches" required>
                                <div class="text-block-344">in.</div>
                            </div>
                            <div class="div-block-374 mobile-entry form _2 weight">
                                <input class="text-field-40 weight w-input" v-model="people.weight" maxlength="256" name="weight-2" data-name="Weight 2" placeholder="Weight in pounds" type="text" id="weight-2" required>
                                <div class="text-block-343 weight">lbs.</div>
                            </div>
                        </div>
                        <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" value="">
                        <input type="submit" data-wait="Please wait..." class="submit-button-2 b w-button" value="Next -&gt;">
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
                <div class="included-space" style="height:280px"></div>
            </div>
            <div class="slide12" v-if="questionId == 4">
                <h1 class="heading-39">What is your <strong>desired weight </strong>?</h1>
                <div class="form-block-3 b update w-form">
                    <form class="form-3 b" @submit.prevent="SaveLead">
                        <div class="div-block-965 b">
                            <div class="div-block-374 mobile-entry form _2 weight">
                                <input class="text-field-40 weight w-input" maxlength="256" v-model="people.desired_weight" name="Goal-Weight" data-name="Goal Weight" placeholder="What is your goal weight in pounds?" type="text" id="Goal-Weight" required>
                                <div class="text-block-343 weight">lbs.</div>
                            </div>
                        </div>
                        <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" value="">
                        <button class="submit-button-2 b w-button" :disabled="submitted" style="display: flex;justify-content: center; align-items: center;">
                            <span v-if="submitted">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="35" height="35" style="shape-rendering: auto; display: block; background: transparent;" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g>
                                        <circle stroke-dasharray="155.50883635269477 53.83627878423159" r="33" stroke-width="11" stroke="#faf8f8" fill="none" cy="50" cx="50">
                                            <animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform>
                                        </circle>
                                        <g></g>
                                    </g>
                                </svg>
                            </span>
                            <span class="" v-else>Start my FREE visit -&gt;</span>
                        </button>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
                <div class="included-space" style="height:280px"></div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import state from "../../utils/state.json"
import modelWindow from '../../common/modelWindow.vue'
export default {
    props: ['baseUrl', 'mainlogo', 'iconSVGColor', 'checkoutlogo'],
    components: {
        'model-window': modelWindow
    },
    data() {
        return {
            showModel: false,
            allstate: state,
            emailError: false,
            submitted: false,
            questionId: 1,
            people: {
                first_name: '',
                last_name: '',
                gender: 'Female',
                email: '',
                phone: '',
                phone_part: {
                    part1: '',
                    part2: '',
                    part3: ''
                },
                state: '',
                agree_to_text: '',
                height_in_inch: '',
                bmi: '',
                height: {
                    ft: '',
                    inch: ''
                },
                weight: '',
                desired_weight: '',
                sms_opted: "Yes",
                ip_address:'',
                tid: '',
                utm_source: '',
                utm_medium: '',
                utm_campaign: '',
                utm_content: '',
                utm_term: '',
            }
        }
    },
    created() {

    },
    watch: {
        questionId() {
            this.scrollToTop();
        }
    },
    mounted() {
        this.scrollToTop();
        this.getUtmParameters();
    },
    methods: {
        getUtmParameters() {
            const urlParams = new URLSearchParams(window.location.search);
            const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'tid'];
            params.forEach(param => {
                this.people[param] = urlParams.get(param) || this.getCookie(param) || '';
            });
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            return parts.length === 2 ? parts.pop().split(';').shift() : null;
        },
        scrollToTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
        checkState() {
            if (["MS", "LA"].includes(this.people.state)) {
                this.showModel = true;
            }
        },
        isEmail(email) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return regex.test(email);
        },
        validateEmail() {
            this.emailError = false;
            let k = this.isEmail(this.people.email);
            if (k) {
                this.questionId++;
            } else {
                this.emailError = true;
            }
        },
        calculateBMI() {
            var heightInInches = parseInt(this.people.height.ft * 12) + parseInt(this.people.height.inch);
            let cal = (this.people.weight * 703);
            let heigh = (heightInInches * heightInInches);
            this.people.height_in_inch = heightInInches;
            var bmi = cal / heigh;
            this.people.bmi = bmi.toFixed(0);
            if (bmi < 27) {
                this.$router.push({
                    name: 'SorryPage'
                });
            } else {
                this.questionId++;
            }
        },
        SaveLead() {
            this.submitted = true;
            this.people.phone = `${this.people.phone_part.part1}${this.people.phone_part.part2}${this.people.phone_part.part3}`;
            try{
                this.people.ip_address = JSON.parse(localStorage.getItem("ip_address"));
            }catch(error){
                console.log(error)
            }
            window.axios.post(`${this.baseUrl}/create-client`, this.people, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                if (response.data.status == 'success') {
                    let post = {
                        id: response.data.id,
                        ...this.people
                    }
                    localStorage.setItem("formResponse", JSON.stringify(post));
                    this.createGoClient(post);
                    post.phone_part = JSON.stringify(post.phone_part);
                    this.$router.push({
                        name: 'StyleThreeReportPage',
                        query: post
                    });
                } else {
                    alert("Error : " + response.data.message);
                    this.loading = false;
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        createGoClient(data) {
            window.axios.post(`${this.baseUrl}/create-go`, data);
        },
        focusNext(event) {
            const currentInput = event.target;
            const nextInput = currentInput.nextElementSibling;
            const prevInput = currentInput.previousElementSibling;
            if (event.key === "Backspace" && currentInput.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            } else if (currentInput.value.length === currentInput.maxLength && nextInput) {
                nextInput.focus();
            }
        },
    }
}
</script>

<style scoped>
.text-danger {
    color: red !important;
    border: solid 1px red !important;
}
</style>
