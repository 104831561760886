<template>
<div>
    <div>
        <div class="form-3-header-div">
            <div class="div-block-966">
                <a href="#" class="w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg" alt="" class="image-30"></a>
            </div>
        </div>
    </div>
    <section>
        <div class="div-block-1668">
            <div class="w-layout-blockcontainer container-23 w-container">
                <div class="div-block-1671">
                    <h1 class="heading-59">Recommended Medications</h1>
                    <p class="paragraph-126">Choose an option below:</p>
                </div>
                <div class="form-block-6 w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="66e1c29d697edf1a632ba1c3" data-wf-element-id="6cb9b46d-c1aa-68ad-5aae-6d38c5369ee5">
                        <div class="presell-form-div-2">
                            <div class="product-box-div _2 presell">
                                <div class="div-block-1025">
                                    <div class="div-block-1024 _1">
                                        <div class="text-block-185">MOST POPULAR</div>
                                    </div>
                                </div>
                                <div class="div-block-1023">
                                    <h1 class="heading-7"><span class="text-span-box _1">Semaglutide</span></h1>
                                </div>
                                <div class="text-block-728 _1 presell">COMPOUND</div>
                                <p class="paragraph-125">The same active ingredient as<br>‍<strong>Ozempic® &amp; Wegovy®</strong></p>
                                <div class="div-block-1672">
                                    <div>
                                        <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                            <div class="text-block-56"><strong>Up to 16%</strong> Weight Loss</div>
                                        </div>
                                        <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                            <div class="text-block-56">Works on <strong>1 </strong>GLP Receptor</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-block-733">Start for only $99 on month 1</div>
                                <div class="div-block-452"><a class="submit-button-7 w-button" href="#" @click="moveToCheckout($event,'Semaglutide',200)" style="align-items: center; justify-content: center; display: flex;">Get Started -&gt;</a></div>
                            </div>
                            <div class="product-box-div _4 presell-form">
                                <div class="div-block-1025">
                                    <div class="div-block-1024 _2">
                                        <div class="text-block-185">MOST EFFECTIVE</div>
                                    </div>
                                </div>
                                <div class="div-block-1023">
                                    <h1 class="heading-7"><span class="text-span-box _2">Tirzepatide</span></h1>
                                </div>
                                <div class="text-block-728 presell">COMPOUND</div>
                                <p class="paragraph-125">The same active ingredient as<br>‍<strong>Mounjaro® &amp; Zepbound®</strong></p>
                                <div class="div-block-1672">
                                    <div>
                                        <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                            <div class="text-block-56"><strong>Up to 22% </strong>Weight Loss*</div>
                                        </div>
                                        <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                            <div class="text-block-56">Works on <strong>2</strong> GLP Receptors.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-block-733">Start for only $199 on month 1</div>
                                <div class="div-block-452"><a class="submit-button-7 w-button" href="#" @click="moveToCheckout($event,'Tirzepatide',250)" style="align-items: center; justify-content: center; display: flex;">Get Started -&gt;</a></div>
                            </div>
                        </div>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="div-block-1667"></div>
            </div>
        </div>
    </section>
    <div class="div-block-1667" style="height:150px"></div>
</div>
</template>

<script>
export default {
    props: ['baseUrl'],
    data() {
        return {
            externalScript: null,
            people: {}
        }
    },
    mounted() {
        this.getParameter();
        this.scrollToTop();
    },
    methods: {
        scrollToTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
        getParameter() {
            this.people = {
                ...this.$route.query,
            }
        },
        moveToCheckout(event, plan, discount) {
            event.preventDefault();
            let data = {
                ...this.people,
                plan: plan,
                discount: discount
            }
            this.$router.push({
                name: 'ThreeCheckoutPage',
                query: data
            });
        }
    }
}
</script>
