<template id="modal-template">
    <div>
      <div class="popup-modal _1" style="display: block;">
            <div>
                <div class="next-steps-box checkout">
                    <div class="next-step-header-div">
                        <div class="text-block-715 checkout">Error Occurred.</div>
                    </div>
                    <div class="step-entry-div checkout" style="display:flex;justify-content: center;width:100%">
                      <div class="div-block-1663" style="display:flex;justify-content: center;width:100%" >
                            <div class="text-block-713 checkout"></div>
                            <div class="text-block-714 checkout">{{message}}</div>
                            <button @click="toggleModal" v-if="message!='No active subscription found.'" style="padding-top:10px" class="submit-button-2 b email w-button">Schedule a new time</button>
                            <router-link to="/checkout?plan=none&phase=1"   style="width: 100%;" v-else>Click Here To Select Plan</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
  </template>
 <script>
 export default {
     props: ["message"],
    data() {
      return {
        showModal: false
      };
    },
    methods: {
      toggleModal() {
        this.$emit("Close");
      }
    },
}
</script>
