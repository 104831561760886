var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('section',[_c('div',{staticClass:"div-block-1668"},[_c('div',{staticClass:"w-layout-blockcontainer container-23 w-container"},[_vm._m(1),_c('div',{staticClass:"form-block-6 w-form"},[_c('form',{attrs:{"id":"email-form","name":"email-form","data-name":"Email Form","method":"get","data-wf-page-id":"66e1c29d697edf1a632ba1c3","data-wf-element-id":"6cb9b46d-c1aa-68ad-5aae-6d38c5369ee5"}},[_c('div',{staticClass:"presell-form-div-2"},[_c('div',{staticClass:"product-box-div _2 presell"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"text-block-728 _1 presell"},[_vm._v("COMPOUND")]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"text-block-733"},[_vm._v("Start for only $99 on month 1")]),_c('div',{staticClass:"div-block-452"},[_c('a',{staticClass:"submit-button-7 w-button",staticStyle:{"align-items":"center","justify-content":"center","display":"flex"},attrs:{"href":"#"},on:{"click":function($event){return _vm.moveToCheckout($event,'Semaglutide',200)}}},[_vm._v("Get Started ->")])])]),_c('div',{staticClass:"product-box-div _4 presell-form"},[_vm._m(6),_vm._m(7),_c('div',{staticClass:"text-block-728 presell"},[_vm._v("COMPOUND")]),_vm._m(8),_vm._m(9),_c('div',{staticClass:"text-block-733"},[_vm._v("Start for only $199 on month 1")]),_c('div',{staticClass:"div-block-452"},[_c('a',{staticClass:"submit-button-7 w-button",staticStyle:{"align-items":"center","justify-content":"center","display":"flex"},attrs:{"href":"#"},on:{"click":function($event){return _vm.moveToCheckout($event,'Tirzepatide',250)}}},[_vm._v("Get Started ->")])])])])]),_vm._m(10),_vm._m(11)]),_c('div',{staticClass:"div-block-1667"})])])]),_c('div',{staticClass:"div-block-1667",staticStyle:{"height":"150px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-3-header-div"},[_c('div',{staticClass:"div-block-966"},[_c('a',{staticClass:"w-inline-block",attrs:{"href":"#"}},[_c('img',{staticClass:"image-30",attrs:{"loading":"lazy","src":"https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1671"},[_c('h1',{staticClass:"heading-59"},[_vm._v("Recommended Medications")]),_c('p',{staticClass:"paragraph-126"},[_vm._v("Choose an option below:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1025"},[_c('div',{staticClass:"div-block-1024 _1"},[_c('div',{staticClass:"text-block-185"},[_vm._v("MOST POPULAR")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1023"},[_c('h1',{staticClass:"heading-7"},[_c('span',{staticClass:"text-span-box _1"},[_vm._v("Semaglutide")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph-125"},[_vm._v("The same active ingredient as"),_c('br'),_vm._v("‍"),_c('strong',[_vm._v("Ozempic® & Wegovy®")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1672"},[_c('div',[_c('div',{staticClass:"div-block-451"},[_c('img',{staticClass:"image-382",attrs:{"width":"75.5","loading":"lazy","alt":"","src":require("@/assets/images/icon_Check-black.svg")}}),_c('div',{staticClass:"text-block-56"},[_c('strong',[_vm._v("Up to 16%")]),_vm._v(" Weight Loss")])]),_c('div',{staticClass:"div-block-451"},[_c('img',{staticClass:"image-382",attrs:{"width":"75.5","loading":"lazy","alt":"","src":require("@/assets/images/icon_Check-black.svg")}}),_c('div',{staticClass:"text-block-56"},[_vm._v("Works on "),_c('strong',[_vm._v("1 ")]),_vm._v("GLP Receptor")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1025"},[_c('div',{staticClass:"div-block-1024 _2"},[_c('div',{staticClass:"text-block-185"},[_vm._v("MOST EFFECTIVE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1023"},[_c('h1',{staticClass:"heading-7"},[_c('span',{staticClass:"text-span-box _2"},[_vm._v("Tirzepatide")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph-125"},[_vm._v("The same active ingredient as"),_c('br'),_vm._v("‍"),_c('strong',[_vm._v("Mounjaro® & Zepbound®")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1672"},[_c('div',[_c('div',{staticClass:"div-block-451"},[_c('img',{staticClass:"image-382",attrs:{"width":"75.5","loading":"lazy","alt":"","src":require("@/assets/images/icon_Check-black.svg")}}),_c('div',{staticClass:"text-block-56"},[_c('strong',[_vm._v("Up to 22% ")]),_vm._v("Weight Loss*")])]),_c('div',{staticClass:"div-block-451"},[_c('img',{staticClass:"image-382",attrs:{"width":"75.5","loading":"lazy","alt":"","src":require("@/assets/images/icon_Check-black.svg")}}),_c('div',{staticClass:"text-block-56"},[_vm._v("Works on "),_c('strong',[_vm._v("2")]),_vm._v(" GLP Receptors.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-form-done"},[_c('div',[_vm._v("Thank you! Your submission has been received!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-form-fail"},[_c('div',[_vm._v("Oops! Something went wrong while submitting the form.")])])
}]

export { render, staticRenderFns }