<template>
<div class="body-3">
    <loading v-if="loading" />
    <div>
      <div class="form-3-header-div">
        <div class="div-block-966">
          <a href="#" class="w-inline-block"><img loading="lazy"
              :src="mainlogo"
              alt="" class="image-30"></a>
          <div class="div-block-967">
            <div class="text-block-152 top">STEP</div>
            <div class="text-block-151">{{this.currentStep + 1}} <span class="text-span-72">of</span> 21</div>
            <div class="text-block-152 bottom">COMPLETE</div>
          </div>
        </div>
      </div>
      <div class="div-block-181">
        <div class="girl-div"></div>
      </div>
    </div>
    <div>
        <div class="div-block-225">
            <div v-for="(question, index) in questionsToAsk" :key="question.id" v-bind:class="{ 'hide': index !== currentStep }">
                <div class="slide-1 " v-if="question.question_type == 'info'">
                    <div class="div-block-226" :id="question.id">
                        <div class="text-block-149" style="display:block;color:red;">{{ question.label }}</div>
                        <a href="#" class="button-17 w-button" @click.prevent="nextStep">Ok! Proceed -&gt;</a>
                        <!--<a href="#" class="link-7">Learn More</a>-->
                    </div>
                </div>
                <div class="slide-1 " v-if="question.question_type == 'image_upload'">
                    <div class="div-block-226" :id="question.id">
                        <div class="text-block-149" style="display:block;">{{ question.label }}</div>
                        <div class="image-upload-container">
                            <div class="upload-btn-wrapper">
                                <input type="file" id="imageUpload" accept="image/*" @change="handleImageUpload($event, question.id)">
                                <label for="imageUpload" class="upload-button" v-if="file == null">
                                    <img src="@/assets/images/cloud_upload.png" alt="Upload Icon" class="upload-icon">
                                </label>
                                <label for="imageUpload" class="upload-button" style="width: 90px" v-else>
                                    <img :src="fileUrl" alt="Upload Icon" class="upload-icon">
                                </label>
                            </div>
                        </div>
                        <button :disabled="imageSubmit" class="button-17 w-button" @click="saveImage($event, question.id)"><span v-if="imageSubmit">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                    </circle>
                                </svg>
                            </span>
                            <span v-else>Next-&gt;</span></button>
                        <!--<a href="#" class="link-7">Learn More</a>-->
                    </div>
                </div>
                <div class="slide6 " v-if="question.question_type == 'radio'">
                    <h1 class="heading-39">{{ question.label }}<br></h1>
                    <div class="form-block-3 w-form">
                        <div class="div-block-965 options-container" :id="question.id">
                            <div class="button-55 w-button w-checkbox-med-options dadio" v-for="(option, index) in question.options_array" :key="index" @click="radioChanged(question.id, option)">
                                <label :id="'radio-' + index" :name="'radio-' + question.id">
                                    <span :for="'radio-' + index">{{ option }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="back-link-div">
                        <a href="#" class="link-block-4 w-inline-block" v-if="question.id !== '9840931'">
                            <div class="text-block-146" @click="prevStep">&lt; Go Back</div>
                        </a>
                    </div>
                </div>
                <div class="slide11 " v-if="question.question_type == 'textarea'">
                    <h1 class="heading-39">{{ question.label }}</h1>
                    <p class="subheaderpcls">{{ question.label2 }}</p>
                    <div class="form-block-3 b w-form">
                        <div class="div-block-965 b" :id="question.id">
                            <input class="text-field-33 email form w-input" maxlength="256" :name="question.id" data-name="Email" placeholder="Please type your answer here..." v-model="formData[question.id]" required>
                        </div>
                        <button class="submit-button-2 b w-button" @click="nextStep">Next -&gt;</button>
                    </div>
                    <div class="back-link-div">
                        <a href="#" class="link-block-4 w-inline-block">
                            <div class="text-block-146" @click="prevStep">&lt; Go Back</div>
                        </a>
                    </div>
                </div>
                <div class="slide11 " v-if="question.question_type == 'number'">
                    <h1 class="heading-39">{{ question.label }}</h1>
                    <div class="form-block-3 b w-form">
                        <div class="div-block-965 b" :id="question.id">
                            <input class="text-field-33 email form w-input" maxlength="256" :name="question.id" data-name="Email" :placeholder="question.label" type="number" v-model="formData[question.id]">
                        </div>
                        <button class="submit-button-2 b w-button" @click="nextStep">Next -&gt;</button>
                    </div>
                    <div class="back-link-div">
                        <a href="#" class="link-block-4 w-inline-block">
                            <div class="text-block-146" @click="prevStep">&lt; Go Back</div>
                        </a>
                    </div>
                </div>
                <div class="slide11 " v-if="question.question_type == 'date'">
                    <h1 class="heading-39">{{ question.label }}</h1>
                    <div class="form-block-3 b w-form">
                        <div class="div-block-965 b" :id="question.id">
                            <input class="text-field-33 email form w-input" maxlength="256" :name="question.id" data-name="Email" :placeholder="question.label" type="date" v-model="formData[question.id]">
                        </div>
                        <button class="submit-button-2 b w-button" @click="nextStep">Next -&gt;</button>
                    </div>
                    <div class="back-link-div">
                        <a href="#" class="link-block-4 w-inline-block">
                            <div class="text-block-146" @click="prevStep">&lt; Go Back</div>
                        </a>
                    </div>
                </div>
                <div class="slide4 " v-if="question.question_type === 'multiple_choice'">
                    <h1 class="heading-39">{{ question.label }}</h1>
                    <div class="form-block-3 b w-form">
                        <div class="div-block-965 options-container" :id="question.id">
                            <label v-for="(option, index) in question.options_array" :key="index" class="w-checkbox checkbox-field-4 w-checkbox-med-options border-one">
                                <input type="checkbox" :id="'checkbox-' + index" class="w-checkbox-input checkbox" :value="option" @change="updateSelectedOptions(question.id, option)">
                                <span class="checkbox-label-4 w-form-label" :for="'checkbox-' + index" v-bind:class="{'noneofabove' : option==='None of the above'}">{{ option}}</span>
                            </label>
                        </div>
                        <button class="submit-button-2 w-button" style="width:100%;" @click="nextStep">Next
                            -&gt;</button>
                    </div>

                    <div class="back-link-div">
                        <a href="#" class="link-block-4 w-inline-block">
                            <div class="text-block-146" @click="prevStep">&lt; Go Back </div>
                        </a>
                    </div>
                </div>
                <div class="slide4" v-if="error" style="margin-top: -100px;">
                    <div class="w-form-fail" style="display:block;">
                        <div>This field is required </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="div-block-181" :style="heightFooter">
    </div>
    
</div>
</template>

    
    
<script>
import questions from "../../utils/refillQues.json"
import dependent_ques from "../../utils/refillQuesDependency.json"

import loading from '../../common/loadingScreen.vue'
export default {
    name: 'HelloWorld',
    props: ['baseUrl', 'mainlogo'],
    components: {
        
        'loading': loading
    },
    data() {
        return {
            formData: {},
            answeredQuestions: [],
            currentStep: 0,
            loading: false,
            questionId: 1,
            pen_cost: 0,
            vial_cost: 0,
            thiskey: 0,
            uploadedFileName: "Drag and drop your image here or click to upload.",
            uploadedFile: null,
            optionsWidth: `calc(50% - 10px)`,
            currentQuestionIndex: 0,
            counter: 0,
            inputFieldRefs: [],
            selectedOptions: {},
            questionsToAsk: questions,
            dependencies: dependent_ques,
            answerdQues: {},
            error: false,
            formResponse: {
                health_id: '',
                id: ''
            },
            fileUrl: null,
            file: null,
            imageSubmit: false,
        }
    },
    computed: {
        heightFooter() {
            var ele = "question" + this.questionId;
            try {
                var myDiv2 = document.getElementById(ele);
                return 'height:' + myDiv2.offsetHeight + 'px;';
            } catch (error) {
                return 'height: 380px;';
            }
        },
    },
    watch: {
        currentStep(newVal) {
            this.scrollToTop();
            if (newVal >= 1) {
                //this.numberAnimate();
                this.focusOnFirstVisibleInput();
            }
        }
    },
    mounted() {
        this.getPaitient();
    },
    methods: {
        scrollToTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            this.file = event.target.files[0];
            console.log(this.file);
            const reader = new FileReader();
            reader.onload = () => {
                this.fileUrl = reader.result;
            };
            reader.readAsDataURL(file);
        },
        async saveImage(event, id) {
            event.preventDefault();
            if (!this.file) {

                return;
            }
            try {
                let filename = this.generateUniqueId();
                filename = filename + "." + this.file.name.split('.').pop().toLowerCase();
                this.imageSubmit = true;
                const response = await window.axios.get('https://cdn.tycoonmach.net/api/for-yara?filename=' + filename + "&content_type=" + this.file.type);
                let url = response.data.postEndpoint;
                let data = response.data.signature;
                this.uploadFile(url, data, this.file, filename, this.file.type, id)
            } catch (error) {
                console.error('Error getting signed URL:', error);
                this.imageSubmit = false;
            }
        },
        async uploadFile(url, data, file, fileName, vrs, id) {
            let vm = this;
            var xhr = new XMLHttpRequest();
            xhr.onerror = function (e) {
                console.log(e)
            };

            xhr.upload.addEventListener('progress', function (e) {
                console.log(e)
            }, false);

            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status >= 200 && xhr.status <= 299) {
                        vm.formData[id] = "https://yaraassets.s3.amazonaws.com/user_med_images/" + fileName;
                        vm.nextStep();
                    } else {
                        console.log("error")
                    }
                }
            };

            function formData(data, file, fileName) {
                var res = new FormData();
                for (var k in data) {
                    if (k == 'Content-Type') {
                        res.append(k, vrs);
                    }
                    if (k == 'key') {
                        res.append(k, 'user_med_images/' + fileName);
                    } else {
                        res.append(k, data[k]);
                    }

                }
                res.append('file', file, fileName);
                return res;
            }

            xhr.open('POST', url, true);
            xhr.send(formData(data, file, fileName));
        },
        generateUniqueId() {
            const combinedInfo = [
                navigator.userAgent,
                navigator.platform,
                new Date().toString(),
                Math.random()
            ].join('');
            let hash = 0;
            for (let i = 0; i < combinedInfo.length; i++) {
                hash = ((hash << 5) - hash) + combinedInfo.charCodeAt(i);
                hash |= 0; // Convert to 32bit integer
            }
            hash = Math.abs(hash);
            return hash.toString(16);
        },
        getPaitient() {
            if (this.$route.query.health_id && this.$route.query.id) {
                this.formResponse.health_id = this.$route.query.health_id;
                this.formResponse.id = this.$route.query.id;
            } else {
                this.$router.push({
                    name: 'QualifyingQuestion',
                });
            }
        },
        focusOnFirstVisibleInput() {
            try {
                const currentStepQuestions = this.questionsToAsk[this.currentStep];
                const targetDiv = document.getElementById(currentStepQuestions.id);
                const firstInput = targetDiv.querySelector('input');
                if (firstInput) {
                    setTimeout(function () {
                        firstInput.focus();
                    }, 10);
                }
            } catch (e) {
                console.log(e);
            }

        },
        updateSelectedOptions(questionId, option) {
            if (!this.formData[questionId]) {
                this.$set(this.formData, questionId, []);
            }
            this.selectedOptions = this.formData[questionId];
            if (typeof (this.selectedOptions) == "string") {
                this.selectedOptions = this.selectedOptions.split(',').map(function (item) {
                    return item.trim();
                });
            }
            if (this.selectedOptions.includes(option)) {
                const index = this.selectedOptions.indexOf(option);
                this.selectedOptions.splice(index, 1);
            } else {
                this.selectedOptions.push(option);
            }
            this.$set(this.formData, questionId, this.selectedOptions.join(','));
        },
        checkDependency(questionId, answer, dependencies) {
            let skipCount = 0;
            dependencies.forEach(dependency => {
                if (dependency.dependent_on === questionId.id) {
                    if (!dependency.ask_if_val_in.includes(answer)) {
                        skipCount++;
                    }
                }
            });
            return skipCount;
        },
        radioChanged(questionId, val) {
            let vm = this;
            this.formData[questionId] = val;
            setTimeout(function () {
                vm.nextStep();
            }, 20);
        },
        nextStep() {
            this.error = false
            this.answeredQuestions.push(this.currentStep);
            const currentQuestion = this.questionsToAsk[this.currentStep];
            const questionId = currentQuestion.id;
            const questionLbl = currentQuestion.question;
            const answer = this.formData[questionId];
            //const nextQuestionId = this.questionsToAsk[this.currentStep + 1].id;
            if (questionId != 0 && (answer == "" || answer == undefined)) {
                this.error = true;
                return false;
            }
            if (currentQuestion.question_type === 'multiple_choice' && Array.isArray(answer)) {
                this.answerdQues[questionLbl] = answer.join(', ')
            } else if (questionId != 0) {
                this.answerdQues[questionLbl] = answer
            }
            console.log(this.currentStep);
            if (this.currentStep == 20) {
                this.submitFormAndMoveToAppointment();
            } else {
                let skip = this.checkDependency(currentQuestion, answer, this.dependencies);
                if (skip == 0) {
                    this.currentStep++;
                } else {
                    this.currentStep += skip + 1;
                }
            }

            localStorage.setItem("answers_to_ques", JSON.stringify(this.answerdQues));
        },
        prevStep(event) {
            event.preventDefault();
            let lastElement = this.answeredQuestions.pop();
            this.currentStep = lastElement;
        },

        submitFormAndMoveToAppointment() {
            this.loading = true;
            let payload = {
                "visit_type": "refill_visit",
                pid: this.formResponse.health_id,
                ...this.answerdQues
            }
            window.axios.post(`${this.baseUrl}/intake-form-two`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                if(response.data.status=="error"){
                    alert("Error : "+ response.data.message);
                    this.loading = false;
                }else if (response.data.data.status == 'SUCCESS') {
                 
                    this.$router.push({
                        name: 'ThankYouRevisit'
                    });
                }
                this.loading = false;
            }).catch((error) => {
                console.log(error)
            });
        },
        updateUserAddress() {
            window.axios.post(this.baseUrl + '/update-address', this.formResponse);
        },
        numberAnimate() {
            let elt = this.$refs.numberAnimate;
            let endNbr = parseInt((((this.currentStep) / 21) * 100).toFixed(0));
            this.incNbrRec(0, endNbr, elt);

        },
        incNbrRec(i, endNbr, elt) {
            let vm = this;

            if (i <= endNbr) {
                elt.innerHTML = i + "%";
                setTimeout(function () {
                    vm.incNbrRec(i + 1, endNbr, elt);
                }, 15);
            }
        },
        increaseCount(event) {
            event.preventDefault();
            this.questionId++;
        },
        getHeight() {

        },

    },

}
</script>

<style scoped>
.w-checkbox-med-options {
    width: calc(50% - 10px);
    padding: 15px;
    box-sizing: border-box;
    margin-right: 10px;
    height: inherit;
    margin-bottom: 15px;
    display: flex;
}

.dadio {
    justify-content: center;
}

.options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.choice-div.radio {
    width: 48%;
    margin-bottom: 15px;
    padding-top: 15px;
}

@media screen and (max-width: 479px) {
    .choice-div.radio.b {
        height: 60px;
        padding-top: 20px;
        font-size: 18px;
        width: 100%;
        margin: 10px 0;
    }

    .choice-div.radio {
        width: 100%;
        margin-bottom: 15px;
        padding-top: 15px;
    }
}

@media screen and (max-width: 479px) {
    .submit-button-2 {
        background-color: #000;
        border-radius: 4px;
        font-size: 20px;
        font-weight: 800;
        line-height: 1;
    }
}

.submit-button-2 {
    border-radius: 4px;
    margin-top: 15px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 16px;
    line-height: 1;
}

.options-container-radio {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.w-checkbox-med-options {
    width: calc(50% - 10px);
    padding: 15px;
    box-sizing: border-box;
    margin-right: 10px;
}

.hide {
    display: none;
    animation: slideOutToBottom 0.5s ease forwards;
}

@keyframes slideOutToBottom {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

.animated-element {
    animation: slideInFromBottom 1s ease forwards;
}

@keyframes slideInFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.image-upload-container {
    width: 300px;
    height: 150px !important;
    margin: 20px auto;
    border: 2px dashed #007bff;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.upload-text {
    font-size: 18px;
    margin-bottom: 10px;
}

.upload-btn-wrapper {
    position: relative;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-button {
    cursor: pointer;
}

.upload-icon {
    width: 50px;
    /* Adjust size as needed */
    height: auto;
    /* Maintain aspect ratio */
}

@media screen and (max-width: 479px) {
    .w-checkbox-med-options {
        width: 100%;
    }
}
</style>
