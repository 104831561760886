<template>
    <div>
        <iframe :src="sourceForIframe" class="miframe" ></iframe>
    </div>
    </template>
    
    <script>
    export default {
        props: ['baseUrl'],
        
        data() {
            return {}
        },
        computed:{
            sourceForIframe(){
                return `https://api.leadconnectorhq.com/widget/bookings/yara/care-navigator?first_name=${encodeURIComponent(this.$route.query.first_name)}&last_name=${encodeURIComponent(this.$route.query.last_name)}&email=${encodeURIComponent(this.$route.query.email)}&phone=${encodeURIComponent(this.$route.query.phone)}`;
            }
        },
        methods: {
    
        },
        created() {
            
        }
    }
    </script>
    <style scoped>
    .miframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;  /* Adjust the width as needed */
        height: 100%; /* Adjust the height as needed */
        border: none; /* Removes the border */
        overflow: hidden; /* Hides the scrollbar */
}

@media only screen and (max-width: 768px) {
    .miframe {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
    }
}
</style>
    