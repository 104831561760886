<template>
    <div style="background-color: #fff;">
        <section>
            <div class="hero-div">

                <div class="hero-logo-div-val">
                    <div class="div-block-1386 _100"><img src="@/assets/images/yara-placeholder-W.svg" loading="lazy"
                            alt="" class="image-283">
                        <div class="text-block-452">$</div>
                        <div class="text-block-453" v-if="check100Discount">100</div>
                        <div class="text-block-453" v-else>50</div>
                        <div class="text-block-454">off!</div><img src="@/assets/images/icon-check.svg" loading="lazy"
                            alt="" class="image-284">
                    </div>
                    <div style="margin-bottom: 10px;"><img src="@/assets/images/valpak.png" loading="lazy" alt="" class="image-283 valpack">
                    </div>

                </div>
                <div class="hero-content-div">
                    <div class="hero-left">
                        <div class="text-block-455"> Get <span class="text-span-98"
                                v-if="check100Discount"><sup>$</sup>100 off</span><span class="text-span-98"
                                v-else><sup>$</sup>50 off</span> today on
                            any Yara prescription weight loss program.</div>

                        <div class="div-block-1428">
                            <div class="text-block-489">Coupon Code</div>
                            <div class="text-block-490" v-if="check100Discount">VALPAK100</div>
                            <div class="text-block-490" v-else>VALPAK50</div>
                        </div>
                        <div class="div-block-1429">
                            <div class="text-block-491">What's next:</div>
                        </div>
                        <div class="div-block-1387"><img src="@/assets/images/icon_check2.svg" loading="lazy" alt=""
                                class="image-291">
                            <div>
                                <div class="text-block-457">Lock in limited time offer.</div>
                            </div>
                        </div>
                        <div class="div-block-1387"><img src="@/assets/images/icon_check2.svg" loading="lazy" alt=""
                                class="image-291">
                            <div>
                                <div class="text-block-457">Lose Weight Guaranteed.<br></div>
                            </div>
                        </div>

                        <a href="#" @click="pushToRoute($event)" class="button-60 w-button"><span v-if="check100Discount">Get Started With $100 Off
                            -&gt;</span><span v-else>Get Started With $50 Off
                            -&gt;</span></a>

                        <div class="countdown-div-2">
                            <div class="text-block-459">This Offer Expires In:</div>
                            <div class="div-block-1389" id="hereitwill">

                            </div>
                        </div>
                    </div>
                    <div class="hero-right">
                        <div class="savings-main-div">
                            <div class="div-block-1431"><img src="@/assets/images/party-popper.png" loading="lazy"
                                    alt="" class="image-292">
                                <div>
                                    <div class="text-block-492"><span class="text-span-99"
                                            v-if="check100Discount"><sup>$</sup>314 total
                                            savings</span> <span class="text-span-99" v-else><sup>$</sup>264 total
                                            savings</span> to start
                                        your Semaglutide weight loss journey!</div>
                                </div>
                            </div>
                            <div class="discount-item-div">
                                <div class="div-block-1432"><img src="@/assets/images/icon_vial-check.svg"
                                        loading="lazy" alt="" class="image-293">
                                    <div class="description-div">

                                        <div class="text-block-493" v-if="check100Discount">First Month Supply with
                                            <sup>$</sup>100 discount
                                        </div>
                                        <div class="text-block-493" v-else>First Month Supply with <sup>$</sup>50
                                            discount
                                        </div>
                                        <div class="text-block-494">Month 2 onwards $299</div>
                                    </div>
                                </div>
                                <div class="savings-price-div">
                                    <div class="text-block-495"><sup>$</sup>299</div>
                                    <div class="text-block-496" v-if="check100Discount"><sup>$</sup>199</div>
                                    <div class="text-block-496" v-else><sup>$</sup>249</div>
                                </div>
                            </div>
                            <div class="discount-item-div">
                                <div class="div-block-1432"><img src="@/assets/images/icon_doctor-check.svg"
                                        loading="lazy" alt="" class="image-293">
                                    <div class="description-div">
                                        <div class="text-block-493">Doctor Support and Monitoring</div>
                                    </div>
                                </div>
                                <div class="savings-price-div">
                                    <div class="text-block-495"><sup>$</sup>99</div>
                                    <div class="text-block-496">FREE</div>
                                </div>
                            </div>
                            <div class="discount-item-div">
                                <div class="div-block-1432"><img src="@/assets/images/icon_testing-check.svg"
                                        loading="lazy" alt="" class="image-293">
                                    <div class="description-div">
                                        <div class="text-block-493">Metabolic testing and blood work included</div>
                                    </div>
                                </div>
                                <div class="savings-price-div">
                                    <div class="text-block-495"><sup>$</sup>75</div>
                                    <div class="text-block-496">FREE</div>
                                </div>
                            </div>
                            <div class="discount-item-div">
                                <div class="div-block-1432"><img src="@/assets/images/icon_delivery-check.svg"
                                        loading="lazy" alt="" class="image-293">
                                    <div class="description-div">
                                        <div class="text-block-493">Express Shipping</div>
                                    </div>
                                </div>
                                <div class="savings-price-div">
                                    <div class="text-block-495"><sup>$</sup>40</div>
                                    <div class="text-block-496">FREE</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: ['baseUrl'],
    components: {

    },
    data() {
        return {
            health_id: '',
            id: '',
        }
    },
    computed: {
        check100Discount() {
            if (this.$route.name == 'ValPackDiscount100') {
                return true;
            } else {
                return false;
            }
        },

    },
    watch: {

    },
    mounted() {
        this.initializeCountdownTimer();
    },
    methods: {
        initializeCountdownTimer() {
            if (typeof window.CountdownTimer !== 'undefined') {
                let offer = "discount50"

                const countdownTimer = new window.CountdownTimer({
                    selector: '#sytim-timer',
                    redirectUrl: '',
                    message: '',
                    offer: offer,
                    style: 'inline',
                    identifier: this.$route.query.id ?? 'ip',
                    containerId: 'hereitwill',
                    fontColor: '#000',
                    backgroundColor: '#fff',
                    fontFamily: "Manrope, sans-serif"
                });
                countdownTimer.startTimer();
            } else {
                console.error('CountdownTimer is not defined.');
            }
        },
        pushToRoute(event) {
            event.preventDefault();
            if (this.$route.name == "ValPackDiscount100") {
                this.$router.push({
                    name: 'QualifyingGLP',
                    query: {
                        "discount": 100
                    }
                });
            } else {
                this.$router.push({
                    name: 'QualifyingGLP',
                    query: {
                        "discount": 100
                    }
                });
            }
        }
    },


}
</script>

<style scoped>
@media screen and (max-width: 479px) {
    .hero-logo-div-val {
        justify-content: center !important;
        align-items: center !important;
        padding-top: 20px;
        display: flex;
        gap: 10px;
    }
}

.hero-logo-div-val {
    justify-content: left;
    align-items: center;
    padding-top: 20px;
    display: flex;
    gap: 15px
}

.valpack{
    width: 100px;
}
.div-block-1389 {
    justify-content: center !important;
}

.countdown-div-2 {
    padding-top: 25px;
}

.line-break-mobile {
    display: none;
}

@media screen and (max-width: 479px) {
    .line-break-mobile {
        display: block;
    }
}
</style>