<template>
    <div id="myModal" class="modal">
        <!-- Modal content -->
        <div class="modal-content">
            <!-- Modal header with exclamation mark and heading -->
            <div class="modal-header">
                <span class="exclamation">!</span>
                <span>State Restriction.</span>
                <span class="close" @click="emitClose()">&times;</span>
            </div>
            <!-- Modal message -->
            <div class="modal-message">
                <p>Sorry based on current state laws we cannot ship compound medications.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        emitClose() {
            this.$emit("close")
        }
    }
}
</script>
<style scoped>
/* Modal container (background) */
.modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 999;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal content */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    /* Wider layout */
    max-width: 500px;
    /* Max width */
    border-radius: 8px;
    /* Rounded corners */
    position: relative;
    /* For close button positioning */
    text-align: center;
    /* Center the text content */
}

@media screen and (max-width: 479px) {
    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        /* Wider layout */
        max-width: 500px;
        /* Max width */
        border-radius: 8px;
        /* Rounded corners */
        position: relative;
        /* For close button positioning */
        text-align: center;
        /* Center the text content */
    }
}

/* Close button */
.close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

/* Modal header */
.modal-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    /* Center heading content */
    align-items: center;
}

.modal-header .exclamation {
    color: #ff0000;
    font-size: 36px;
    margin-right: 10px;
}

/* Modal message */
.modal-message {
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
    /* Center message text */
}
</style>