<template>
    <div>
        <model-window v-if="showModel" @close="showModel = false" />
        <div>
            <div class="new-summary">
                <div class="header-div"><img loading="lazy" :src="checkoutlogo" alt="" class="image-242">
                    <a href="https://yarahealth.helpscoutdocs.com/" target="_blank"
                        class="link-block-18 w-inline-block">
                        <div class="text-block-342">Need Help</div>
                        <div class="help-div">
                            <div class="text-block-415">?</div>
                        </div>
                    </a>
                </div>
                <div class="dropdown-sum">
                    <div class="order-summary-div">
                        <div data-delay="0" data-hover="false" class="order-dropdown w-dropdown"
                            v-bind:class="{ 'zindexcls': dropOpen }">
                            <div class="dropdown-sum-toggle w-dropdown-toggle" @click.prevent="dropOpen = !dropOpen"
                                v-bind:class="{ 'w--open': dropOpen }">
                                <div class="icon-19 w-icon-dropdown-toggle" v-bind:class="{ 'mkeup': dropOpen }"></div>
                                <div class="div-block-1282">
                                    <div class="text-block-327">Semaglutide Monthly</div>
                                    <div class="no-discount-price" v-if="discount > 0">${{ people.amount }}</div>
                                    <div class="text-block-328">${{ getPrice }}</div>
                                </div>
                                <div class="div-block-1283">
                                    <div class="text-block-333"> FREE Shipping</div>
                                </div>
                            </div>
                            <nav class="dropdown-summary w-dropdown-list" v-bind:class="{ 'w--open': dropOpen }">
                                <div class="div-block-269 _1 new summary-drop">
                                    <div class="div-block-960 new summary-drop">
                                        <div class="div-block-976">
                                            <div class="div-block-974"><img sizes="100vw"
                                                    srcset="@/assets/images/cart-image_TrizepatideVial-p-500.jpg 500w, @/assets/images/cart-image_TrizepatideVial-p-800.jpg 800w, @/assets/images/cart-image_TrizepatideVial.jpg 1000w"
                                                    alt="" src="@/assets/images/cart-image_TrizepatideVial.jpg"
                                                    loading="lazy" class="image-36">
                                                <div class="div-block-975">
                                                    <div class="text-block-161">1</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="text-block-87">Semaglutide Program</div>
                                                <div class="text-block-162">Billed Monthly</div>
                                            </div>
                                        </div>
                                        <div class="text-block-141 new">${{ getPrice }}</div>
                                        <div class="text-block-329">${{ getPrice }}</div>
                                    </div>
                                </div>
                                <div class="form-block-9 drop-sum w-form">
                                    <form id="email-form-4" @submit.prevent="checkCoupon()">
                                        <div class="div-block-977 checkout step-1">
                                            <div class="div-block-1368"><input class="text-field-5 coupon new w-input"
                                                    v-bind:class="{ 'text-danger': !valid_coupon }" v-model="coupon"
                                                    maxlength="256" name="field-3" data-name="Field 3"
                                                    placeholder="Coupon Code" type="text" id="field-3" required>
                                                <input type="submit" class="button-15 new w-button" value="Apply">
                                            </div>
                                            <div class="coupons-div">
                                                <div class="discount" v-if="discount > 0">
                                                    <div class="text-block-426"><sup>$</sup>{{ discount }} Off</div>
                                                    <a href="#" class="remove-discount w-inline-block"
                                                        @click.prevent="discount = 0">
                                                        <div class="text-block-427">x</div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="div-block-269 _2 new drop-sum">
                                    <div class="div-block-962 new">
                                        <div class="text-block-331">Subtotal</div>
                                        <div class="text-block-332">${{ getPrice }}</div>
                                    </div>
                                    <div class="div-block-962 new">
                                        <div class="text-block-331">Shipping</div>
                                        <div class="text-block-332">FREE</div>
                                    </div>
                                </div>
                                <div class="div-block-269 _2 new drop-sum total">
                                    <div class="div-block-962 new drop-sub">
                                        <div class="text-block-88 _2 after"><strong>Total</strong></div>
                                        <div class="text-block-163"><strong class="bold-text-3">${{ getPrice }}</strong>
                                        </div>
                                    </div>
                                    <div class="text-block-341">Subscription fee may increase if prescribed a higher
                                        dose of medication.
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="text-block-336 step-1">(Tap above to access full order details)</div>
                </div>
            </div>
            <div class="div-block-263">
                <div class="div-block-265">
                    <div class="div-block-266 new last step1"><img loading="lazy" :src="checkoutlogo" alt=""
                            class="image-31 summary">
                        <div class="checkout-div step-1">
                            <div class="div-block-1291 step-1">
                                <div class="text-block-250 checkout"><strong>Shipping &gt; Payment</strong></div>
                            </div>
                            <div class="w-form">
                                <form id="email-form-2" @submit.prevent="SubmitDetails()"
                                    class="step-2-left-payment-form new-checkout new-mobile checkout-desk step-1">
                                    <div class="div-block-1292 step-1"><label for="email-2"
                                            class="field-label-4 step-1">Contact</label><input
                                            class="text-field-7 new step-1 w-input"
                                            v-bind:class="{ 'text-danger': emailError }" v-model="people.email"
                                            maxlength="256" name="email-2" data-name="Email 2"
                                            placeholder="Email Address" type="email" id="email-2" required><label
                                            for="email-3" class="field-label-4 step-1">Shipping
                                            Information</label>
                                        <div class="div-block-1363">
                                            <div class="div-block-1364"><input class="form-text-field w-input"
                                                    maxlength="256" v-model="people.first_name" name="first-name"
                                                    data-name="first name" placeholder="First Name" type="text"
                                                    id="first-name">
                                            </div>
                                            <div class="div-block-1364"><input class="form-text-field w-input"
                                                    maxlength="256" v-model="people.last_name" name="Last-Name"
                                                    data-name="Last Name" placeholder="Last Name" type="text"
                                                    id="Last-Name" required></div>
                                        </div><input class="text-field-7 new step-1 w-input" maxlength="256"
                                            name="Date-of-Birth" v-model="people.dob"
                                            v-bind:class="{ 'text-danger': hasError }" @input="formatDate"
                                            @keydown="handleBackspace" data-name="Date of Birth"
                                            placeholder="Date of Birth mm/dd/yyyy" type="text" id="Date-of-Birth-2"
                                            required>
                                            <div class="addrsinpuwithaptskinny">
                                                <vue-google-autocomplete
                                            v-bind:class="{ 'pac-target-input text-danger': addressError }"
                                            class="text-field-7 new step-1 w-input" id="google-autocomplete"
                                            placeholder="Address" v-on:placechanged="getAddressData" types="geocode"
                                            country="us"></vue-google-autocomplete>
                                            <input type="text" id="google-autocomplete" v-model="people.address_line_2" placeholder="APT#" class="text-field-7 new step-1 w-input pac-target-input aptinputskinny" >
                                        </div>
                                        <label for="email-3" class="field-label-4 step-1">Phone Number</label>
                                        <div class="div-block-1366 mobile-entry"><input
                                                class="phone-number-field _1 step-1 w-input" pattern="[0-9]*"
                                                maxlength="3" minlength="3" @keyup="focusNext" placeholder="(000)"
                                                type="text" id="field-2" v-model="people.phone_part.part1" required>
                                            -<input class="phone-number-field step1 w-input"
                                                v-model="people.phone_part.part2" pattern="[0-9]*" maxlength="3"
                                                minlength="3" @keyup="focusNext" placeholder="000" type="text"
                                                id="field-2" required>
                                            -<input class="phone-number-field _2 step-1 w-input" pattern="[0-9]*"
                                                maxlength="4" minlength="4" @keyup="focusNext" name="field-2"
                                                data-name="Field 2" placeholder="0000" v-model="people.phone_part.part3"
                                                type="text" id="field-2" required>
                                        </div><input type="submit" data-wait="Please wait..."
                                            class="submit-button-3 new w-button" value="Continue to Payment -&gt;">
                                    </div>
                                    <div class="div-block-1154"><label
                                            class="w-checkbox checkbox-field-9 terms-get-started">By clicking
                                            &quot;Continue to Payment,&quot; you agree to receive communications from
                                            Yara, including
                                            AI-generated calls, automated dialing systems, text messages, emails, and
                                            other communication
                                            methods, to the contact information provided. These communications may
                                            include promotions,
                                            updates, and follow-up information related to your inquiry or our services.
                                            Standard message and
                                            data rates may apply. You can opt-out at any time. For more details, please
                                            review our <a href="https://joinyara.com/privacy-policy-new"
                                                target="_blank">Privacy Policy</a></label></div>
                                </form>
                                <div class="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div class="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="div-block-1288 hideifmobile">
                        <div class="div-block-1348">
                            <a href="https://yarahealth.helpscoutdocs.com/" target="_blank"
                                class="link-block-22 w-inline-block">
                                <div class="text-block-406">Need Help</div>
                                <div class="help-div">
                                    <div class="text-block-415">?</div>
                                </div>
                            </a>
                        </div>
                        <div class="summary-desk step-2">
                            <div class="div-block-269 _1 new">
                                <div class="div-block-960 new">
                                    <div class="div-block-976">
                                        <div class="div-block-974">
                                            <div class="checkout-product-div"></div>
                                            <div class="div-block-975">
                                                <div class="text-block-161">1</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-block-87">Semaglutide Program</div>
                                            <div class="text-block-162">Billed Monthly</div>
                                        </div>
                                    </div>
                                    <div class="no-discount-price" v-if="discount > 0"><strong>${{ people.amount
                                            }}</strong>
                                    </div>
                                    <div class="text-block-347">${{ getPrice }}</div>
                                </div>
                            </div>
                            <div class="div-block-269 _1 coupon new">
                                <div class="form-block-9 w-form">
                                    <form id="email-form-4" @submit.prevent="checkCoupon()">
                                        <div class="div-block-977 checkout step-1">
                                            <div class="div-block-1368"><input v-model="coupon"
                                                    v-bind:class="{ 'text-danger': !valid_coupon }"
                                                    class="text-field-5 coupon new w-input" maxlength="256"
                                                    name="field-3" data-name="Field 3" placeholder="Coupon Code"
                                                    type="text" id="field-3" required>
                                                <input type="submit" class="button-15 new w-button" value="Apply">
                                            </div>
                                            <div class="coupons-div">
                                                <div class="discount" v-if="discount > 0">
                                                    <div class="text-block-426"><sup>$</sup>{{ discount }} Off</div>
                                                    <a href="#" class="remove-discount w-inline-block"
                                                        @click.prevent="discount = 0">
                                                        <div class="text-block-427">x</div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="div-block-269 _2 new">
                                    <div class="div-block-962 new">
                                        <div class="text-block-88 _2 after"><strong>Subtotal</strong></div>
                                        <div class="text-block-163">${{ getPrice }}</div>
                                    </div>
                                    <div class="div-block-962 new">
                                        <div class="text-block-88 _2 after"><strong>Shipping</strong></div>
                                        <div class="text-block-163">FREE</div>
                                    </div>
                                </div>
                            </div>
                            <div class="div-block-1289">
                                <div class="text-block-348">Total</div>
                                <div class="text-block-348">${{ getPrice }}</div>
                            </div>
                            <div class="text-block-90">Monthly Access. <span class="text-span-13">Cancel anytime.
                                </span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import modelWindow from '../../common/modelWindow.vue'
export default {
    props: ['baseUrl', 'mainlogo', 'iconSVGColor', 'checkoutlogo'],
    components: {
        VueGoogleAutocomplete,
        'model-window': modelWindow
    },
    data() {
        return {
            showModel: false,
            dropOpen: false,
            discount: 0,
            valid_coupon: true,
            coupon: '',
            hasError: false,
            emailError: false,
            addressError: false,
            people: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                dob: '',
                pay_terms: 1,
                phone_part: {
                    part1: '',
                    part2: '',
                    part3: ''
                },
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                address: '',
                amount: 299,
                ip_address: '',
                tid: '',
                utm_source: '',
                utm_medium: '',
                utm_campaign: '',
                utm_content: '',
                utm_term: '',
                step_completed: 1
            }
        }
    },
    mounted() {
        this.checkUrlParameters();
    },
    computed: {
        getPrice() {
            let discountedPrice = this.people.amount - this.discount;
            if (discountedPrice % 1 !== 0) {
                discountedPrice = Math.round(discountedPrice * 100) / 100;
            }
            return discountedPrice;
        },
        isMobile() {
            return window.innerWidth <= 768;
        },
        validateDate() {
            const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;
            let dob = this.people.dob;
            return regex.test(dob) && this.isValidDate(dob)
        },
        validateEmail() {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return regex.test(this.people.email)
        },
        isAddressComplete() {
            const {
                address_line_1,
                city,
                state,
                zip
            } = this.people;
            return address_line_1 && city && state && zip;
        }
    },
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        },
        getQueryParamOrCookie(url, paramName) {
            const urlObj = new URL(url);
            const params = new URLSearchParams(urlObj.search);
            let value = params.get(paramName);
            if (value !== null) {
                return value;
            }
            value = this.getCookie(paramName);
            return value;
        },
        getAffiliate() {
            const url = window.location.href;
            const affiliateIdKey = 'utm_campaign';
            const tidKey = 'tid';
            const utm_sourceKey = 'utm_source';
            const affiliateId = this.getQueryParamOrCookie(url, affiliateIdKey);
            const tid = this.getQueryParamOrCookie(url, tidKey);
            const utm_source = this.getQueryParamOrCookie(url, utm_sourceKey);
            if (affiliateId !== null) {
                this.people.utm_campaign = affiliateId;
            }
            if (tid !== null) {
                this.people.tid = tid;
            }
            if (utm_source !== null) {
                this.people.utm_source = utm_source;
            }
        },
        checkUrlParameters() {
            const urlParams = new URLSearchParams(window.location.search);
            const first_name = urlParams.get('first_name');
            const last_name = urlParams.get('last_name');
            const email = urlParams.get('email');
            const phone = urlParams.get('phone');
            const discount = urlParams.get('discount');
            urlParams.forEach((value, key) => {
                this.people[key] = value;
            });
            if (first_name) this.people.first_name = first_name;
            if (last_name) this.people.last_name = last_name;
            if (email) this.people.email = email;
            if (discount) this.discount = discount;
            if (phone) {
                let phoneNumber = phone.toString();
                this.people.phone = phone;
                const part1 = phoneNumber.slice(0, 3);
                const part2 = phoneNumber.slice(3, 6);
                const part3 = phoneNumber.slice(6);
                this.people.phone_part = {
                    part1: part1,
                    part2: part2,
                    part3: part3
                };
            } 
            this.people.amount = 299;
            if (this.discount != 50 && this.discount != 100 && this.discount != 110) {
                this.discount = 0;
            }
            if (this.$route.query.specialoffer == "true") {
                this.people.discount = 339.01;
                this.discount = 339.01;
                this.people.amount = 349;
            }else if(this.$route.query.offer=="fortynine"){
                    this.people.discount= 300;
                    this.discount= 300;
                    this.people.amount=349;
            }else if(this.$route.query.offer=="ninetynine"){
                    this.people.discount= 250;
                    this.discount= 250;
                    this.people.amount=349;
            } else {
                console.log("its not offer")
            }
            this.getAffiliate();
        },
        formatDate() {
            let dobValue = this.people.dob.replace(/\D/g, '');
            if (dobValue.length >= 2) {
                dobValue = dobValue.slice(0, 2) + '/' + dobValue.slice(2);
            }
            if (dobValue.length >= 5) {
                dobValue = dobValue.slice(0, 5) + '/' + dobValue.slice(5);
            }
            this.people.dob = dobValue;
        },
        handleBackspace(event) {
            if (event.key === 'Backspace' && (this.people.dob.endsWith('/') || this.people.dob.length === 3)) {
                this.people.dob = this.people.dob.slice(0, -1);
            }
        },
        isValidDate(dob) {
            const [month, day, year] = dob.split('/').map(Number);
            const date = new Date(year, month - 1, day);
            return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
        },
        SubmitDetails() {
            this.emailError = !this.validateEmail;
            this.hasError = !this.validateDate;
            this.addressError = !this.isAddressComplete;
            if (!this.emailError && !this.hasError && !this.addressError) {
                if (["MS", "LA"].includes(this.people.state)) {
                    this.$router.push({
                        name: 'SorryPage'
                    });
                } else {
                    this.people.discount = this.discount;
                    this.people.phone = this.people.phone_part.part1 + this.people.phone_part.part2 + this.people.phone_part.part3;
                    this.people.brand = window.location.hostname == "ifg.joinyara.com" ? "It Feels Good" : "Yara";
                    try{
                        this.people.ip_address = JSON.parse(localStorage.getItem("ip_address"));
                    }catch(error){
                        console.log(error)
                    }
                    let temp = this.people;
                    temp.height = {
                        ft: 0,
                        inch: 0
                    }

                    window.axios.post(this.baseUrl + '/flow-one-leads', temp, {
                        headers: {
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "*"
                        }
                    }).then((response) => {
                        console.log("sent data 2")
                        console.log(response)
                    }).catch((error) => {
                        console.log(error)
                    });

                    this.letfbKnow(this.people);
                    this.$router.push({
                        name: 'checkoutWithBasicStep2',
                        query: this.people
                    });
                }
            }
        },
        letfbKnow(people) {
            try {
                window.axios.post(this.baseUrl + '/let-fb-know', {
                    first_name: people.first_name,
                    last_name: people.last_name,
                    email: people.email,
                    ip_address: people.ip_address,
                    userAgent: navigator.userAgent,
                    utm_source: people.utm_source,
                    event: "AddToCart",
                });
            } catch (error) {
                console.log(error)
            }

        },
        checkCoupon() {
            this.discount = 0;
            this.valid_coupon = true;
            window.axios.post(this.baseUrl + '/validate-coupon', {
                coupon: this.coupon
            }).then((response) => {
                if (response.data.status == 'success') {
                    this.valid_coupon = true;
                    this.couponapplied = true;
                    if (response.data.c_type == 1) {
                        this.discount = response.data.c_val;
                    } else {
                        this.discount = Math.round((this.people.amount * response.data.c_val) / 100);
                    }
                } else {
                    this.valid_coupon = false;
                }
                this.mkey++;
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        focusNext(event) {
            const currentInput = event.target;
            const nextInput = currentInput.nextElementSibling;
            const prevInput = currentInput.previousElementSibling;
            if (event.key === "Backspace" && currentInput.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            } else if (currentInput.value.length === currentInput.maxLength && nextInput) {
                nextInput.focus();
            }
        },
        getAddressData(place) {
            if (["MS", "LA"].includes(place.administrative_area_level_1)) {
                this.showModel = true;
            } else {
                this.people.address_line_1 = place.street_number + ' ' + place.route;
                this.people.city = place.locality;
                this.people.state = place.administrative_area_level_1;
                this.people.zip = place.postal_code;
                if (this.people.city == undefined) {
                    this.people.city = place.administrative_area_level_2;
                }
                localStorage.setItem("userState", JSON.stringify(this.people.state));
            }
        },
    },
    created() {

    }
}
</script>

<style scoped>
@media screen and (max-width: 479px) {
    .form-text-field {
        border: 2px solid var(--field-box-outline);
    }
}

.terms-get-started {
    font-size: 10px;
    font-weight: normal;
    line-height: normal;
}

@media (max-width: 768px) {
    .hideifmobile {
        display: none;
    }

    .checkbox-field-9 {
        padding-left: 2%;
    }
}


.pac-target-input {
    border: 1px solid !important;
    border-color: var(--field-box-outline) !important;
}

@media screen and (max-width: 479px) {
    .pac-target-input {
        border: 2px solid !important;
        border-color: var(--field-box-outline);
    }

    .w-input,
    .w-select,
    .phone-number-field._1,
    .phone-number-field._2,
    .phone-number-field._3 {
        font-size: 18px;
    }

}

.mkeup {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.text-danger {
    color: red !important;
    border: solid 1px red !important;
}

.aptinputskinny {
    max-width: 20%;
    margin-left: 5px;
}
.addrsinpuwithaptskinny {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}
</style>
